import {
  addRangeToSet,
  epochSeconds,
  findMatchWithinIndex,
  getReferenceToMasalaIdMap,
  normalizedMatchWithinWordIndex,
  parseVocabElement,
  rangeIntersectsSet,
  recomputeHashForElement,
  sortScriptElements,
  stringToStrongNormalizedWordArray,
} from '@masala-lib/llm/llm-funcs';
import {
  ElementCategory,
  ScriptElement,
  ScriptElementKind,
  SlotKey,
  TimesLookup,
} from '@masala-lib/llm/llm-types';
import {
  computeSlotConflicts,
  getFlag,
  minusSet,
  setFlag,
  structuralTaskComputeElementKeys,
  toggleFlag,
} from '@masala-lib/llm/project/llm-project-funcs';
import {
  ARCHIVED,
  FlagsData,
  LintAlert,
  MASALA_FLAG,
  NotesData,
  NumberSet,
  ProjectTask,
  SAMOSA_FLAG,
  SUPPRESSED,
  Signoff,
  SignoffsData,
  SuppressionsData,
  SynthElementFunction,
} from '@masala-lib/llm/project/llm-project-types';
import { epochSecondsFloat, randomString } from '@masala-lib/utils';
import { IndexRange, NO_INDEX } from '@tikka/basic-types';
import { searchSorted } from '@tikka/intervals/search-sorted';
import {
  ObservableMap,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  untracked,
} from 'mobx';
import { runTextFormModal } from 'samosa/ui/components/text-form-modal';
// import { TranslationMergeModal } from '../ui/merge/translation-merge-script-modal';
// import { openModal } from '../ui/utils/imperative-modal';
import { createLogger } from '@app/logger';
import { alertError } from 'ui/misc-utils';
import { last, uniq } from 'lodash';
import { runSimpleConfirmation } from 'samosa/ui/components/simple-confirm';
import { getSamosaModel } from './samosa-model-handle';
import { StructuredPlayer } from '@tikka/player/structured-player';
import { Interval, fromIntervals } from '@tikka/intervals/intervals';
import { CreateTracker } from '@tikka/tracking/tracker';
import { onlyFirstCapitalized } from '@masala-lib/misc/editorial-string-utils';
import { O } from '@cassiozen/usestatemachine/dist/types';

const log = createLogger('touchup-editor-modal');

export type TouchupEditorOptions = {
  canHaveMissingReferences?: boolean;
  canHaveConflicts?: boolean;
  canHaveLintAlerts?: boolean;
  requireSignoff?: boolean;
  editIsAdd?: boolean;
  enableMerge?: boolean;
  disablePick?: boolean;
  task: ProjectTask;
  elementCategory: (element: ScriptElement) => ElementCategory;
  elementIsReference: (element: ScriptElement) => boolean;
  elementIsOutput: (element: ScriptElement) => boolean;
  elementIsDecorator: (element: ScriptElement) => boolean;
  elementIsComparison: (element: ScriptElement) => boolean;
  computeElementKeys: (element: ScriptElement, override?: boolean) => void;
  synthElementFunc: SynthElementFunction;
};

export class TouchUpEditorModal {
  @observable open = true;
  lastUpdateTime = 0;
  lastSaveTime = 0;
  @observable showArchived = false;
  @observable collapsed = false;
  save = false;
  baseScript: ScriptElement[];
  referenceScript: ScriptElement[];
  exchangeElementAlerts: LintAlert[];
  canHaveMissingReferences: boolean;
  canHaveConflicts: boolean;
  canHaveLintAlerts: boolean;
  requireSignoff: boolean;
  disablePick: boolean;
  enableMerge: boolean;
  player: StructuredPlayer = null;
  task: ProjectTask = null;
  elementCategory: (element: ScriptElement) => ElementCategory;
  elementIsReference: (element: ScriptElement) => boolean;
  elementIsOutput: (element: ScriptElement) => boolean;
  elementIsDecorator: (element: ScriptElement) => boolean;
  elementIsComparison: (element: ScriptElement) => boolean;
  elementIsNavigable: (element: ScriptElement) => boolean;
  elementIsSlotClaiming: (element: ScriptElement) => boolean;
  computeElementKeys: (element: ScriptElement, override?: boolean) => void;
  synthElementFunc: SynthElementFunction = null;
  // referenceToReferenceScriptElementId: { [index in SlotKey]: string };
  elementFlagsEditsMap: ObservableMap<string, number> = observable.map({});
  alertSuppressionsEditsMap: ObservableMap<string, boolean> = observable.map(
    {}
  );
  activeSuppressions: FlagsTouchupBuffer = null;
  activeArchivals: FlagsTouchupBuffer = null;
  activePendingFlags: FlagsTouchupBuffer = null;
  activeDeferredFlags: FlagsTouchupBuffer = null;
  activeAlertSuppressions: BooleanTouchupBuffer = null;
  notesEditsMap: ObservableMap<string, string> = observable.map({});
  activeNotes: TouchupBuffer<string> = null;
  picksEditsMap: ObservableMap<string, Signoff> = observable.map({});
  activePicks: TouchupBuffer<Signoff> = null;
  activeEditsMap: ObservableMap<string, ScriptElement> = observable.map(
    {},
    { deep: false }
  );
  addedElementMap: ObservableMap<string, ScriptElement> = observable.map(
    {},
    { deep: false }
  );
  addElementPrototype: Partial<ScriptElement> = {};
  effectiveTimestamp: number = 0;
  @observable currentElementId: string = null;

  @observable editingElementId: string = null;

  @observable
  showNotes: boolean = true; // show by default for now

  @observable
  showLosers: boolean = false;

  @observable
  editingANote: boolean = false;

  editIsAdd: boolean = false;

  refNumberToTimes: { [index in number]: Interval };
  sentences: ScriptElement[] = [];
  refToSentence: { [index in number]: ScriptElement } = {};
  sentenceTimes: { [index in string]: Interval };

  endTime = 0;

  disposers: (() => void)[] = [];

  constructor(
    baseScript: ScriptElement[],
    elementFlags: FlagsData,
    notes: NotesData,
    signoffs: SignoffsData,
    elementAlerts: LintAlert[],
    alertSuppressions: SuppressionsData,
    timesLookup: TimesLookup,
    player: StructuredPlayer,
    options: TouchupEditorOptions
  ) {
    this.player = player;
    this.canHaveConflicts = !!options?.canHaveConflicts;
    this.canHaveMissingReferences = !!options?.canHaveMissingReferences;
    this.canHaveLintAlerts = !!options?.canHaveLintAlerts;
    this.requireSignoff = !!options?.requireSignoff;
    this.task = options?.task || 'translation';
    this.disablePick = !!options?.disablePick;
    this.editIsAdd = !!options?.editIsAdd;
    this.enableMerge = !!options?.enableMerge;
    this.baseScript = baseScript;
    this.referenceScript = baseScript.filter(el => el.origin === 'MASALA');
    // this.referenceToReferenceScriptElementId = getReferenceToMasalaIdMap(
    //   this.referenceScript
    // );
    // this.neededReferenceSet = new Set(
    //   this.referenceScript.map(e => e.reference)
    // );
    this.activeSuppressions = new FlagsTouchupBuffer(
      this.elementFlagsEditsMap,
      elementFlags,
      SUPPRESSED
    );
    this.activeArchivals = new FlagsTouchupBuffer(
      this.elementFlagsEditsMap,
      elementFlags,
      ARCHIVED
    );
    this.activePendingFlags = new FlagsTouchupBuffer(
      this.elementFlagsEditsMap,
      elementFlags,
      SAMOSA_FLAG
    );
    this.activeDeferredFlags = new FlagsTouchupBuffer(
      this.elementFlagsEditsMap,
      elementFlags,
      MASALA_FLAG
    );
    this.activeAlertSuppressions = new BooleanTouchupBuffer(
      this.alertSuppressionsEditsMap,
      alertSuppressions
    );
    this.activeNotes = new TouchupBuffer(this.notesEditsMap, notes);
    this.activePicks = new TouchupBuffer(this.picksEditsMap, signoffs);
    // TODO hacking
    this.addElementPrototype = {
      kind: 'TRANSLATION',
    };
    // note, this now reflects as selected visually.
    // but modal still doesn't have focus automatically yet for keyboard controls when opening
    this.elementCategory = options.elementCategory;
    this.elementIsReference = options.elementIsReference;
    this.elementIsOutput = options.elementIsOutput;
    this.elementIsComparison = options.elementIsComparison;
    this.elementIsDecorator = options.elementIsDecorator;
    this.computeElementKeys = options.computeElementKeys;
    this.synthElementFunc = options.synthElementFunc;
    this.elementIsNavigable = (element: ScriptElement) =>
      !options.elementIsDecorator(element);
    this.elementIsSlotClaiming = (element: ScriptElement) =>
      !!element.claimedSlot;

    let refNumberToTimes: { [index in number]: Interval } = null;
    let sentenceTimes: { [index in string]: Interval } = null;
    let lastTime = 0;
    const sentences = this.referenceScript.filter(el => el.kind === 'SENTENCE');
    const refToSentence: { [index in number]: ScriptElement } = {};
    for (const sentence of sentences) {
      refToSentence[sentence.reference] = sentence;
    }
    this.refToSentence = refToSentence;
    this.sentences = sentences;
    if (timesLookup) {
      refNumberToTimes = {};
      sentenceTimes = {};
      for (const refEl of this.sentences) {
        refNumberToTimes[refEl.reference] = timesLookup[refEl.id];
        const interval = timesLookup[refEl.id];
        if (!interval) {
          sentenceTimes[refEl.id] = null;
          continue;
        }
        sentenceTimes[refEl.id] = interval;
        lastTime = interval.end;
      }
      this.endTime = lastTime;
    }
    this.refNumberToTimes = refNumberToTimes;
    this.sentenceTimes = sentenceTimes;
    this.exchangeElementAlerts = elementAlerts;
    makeObservable(this);
    this.currentElementId = this.navigableScript[0].id;
    this.initializeTrackUpdates();
  }

  markUpdate() {
    this.lastUpdateTime = epochSecondsFloat();
  }

  initializeTrackUpdates() {
    // TODO dispose
    this.disposers.push(
      reaction(
        () => [
          ...this.elementFlagsEditsMap.values(),
          ...this.activeEditsMap.values(),
          ...this.addedElementMap.values(),
          ...this.notesEditsMap.values(),
          ...this.alertSuppressionsEditsMap.values(),
        ],
        () => this.markUpdate(),
        { equals: () => false }
      )
    );
  }

  dispose() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  @computed({ keepAlive: true })
  get elementAlerts(): LintAlert[] {
    return [].concat(this.exchangeElementAlerts, this.reactiveElementAlerts);
  }

  @computed
  get reactiveElementAlerts(): LintAlert[] {
    if (this.task === 'vocab') {
      const result: LintAlert[] = [];
      const claimedAddresses = new Set();
      const elements0 = this.displayScript.filter(el => el.kind === 'VOCAB');
      const elements = elements0.map(
        el => this.activeEditsMap.get(el.id) ?? el
      );
      for (const el of elements) {
        if (!el?.reference) {
          continue;
        }
        const parsed = parseVocabElement(el);
        const editted = el.origin === 'EDIT' || el.origin === 'ADD';
        if (!parsed && editted) {
          result.push({
            kind: 'PARSE_DETAIL',
            elementId: el.id,
            message: `unable to parse vocab element`,
            key: `PARSE_DETAIL:${el.hash}`,
            reference: el.reference,
            level: 'WARNING',
          });
          continue;
        }
        const sentence = this.refToSentence[el.reference];
        const sentenceText = sentence.text;
        const aParts = stringToStrongNormalizedWordArray(sentenceText);
        const bParts = stringToStrongNormalizedWordArray(parsed.section);
        const index = findMatchWithinIndex(aParts, bParts);
        if (index === NO_INDEX && editted) {
          result.push({
            kind: 'RECONCILE',
            message: `cannot find match for vocab section in sentence`,
            key: `RECONCILE:${el.hash}`,
            elementId: el.id,
            reference: el.reference,
            level: 'WARNING',
          });
          continue;
        }
        if (index === NO_INDEX || !parsed) {
          continue;
        }
        const begin = index;
        const end = begin + bParts.length - 1;
        const indexRange = { begin, end };
        const prefix = sentence.id;
        if (rangeIntersectsSet(indexRange, claimedAddresses, prefix)) {
          result.push({
            kind: 'OVERLAP',
            message: `vocab intersects with other vocab`,
            key: `OVERLAP:${el.hash}`,
            elementId: el.id,
            reference: el.reference,
            level: 'WARNING',
          });
        }
        addRangeToSet(indexRange, claimedAddresses, prefix);
      }
      return result;
    }
    return [];
  }

  get projectIsStructural() {
    return this.task === 'structural';
  }

  elementIsSlotHead(el: ScriptElement) {
    return !!el.slots;
  }

  @computed
  get slotKeyToSlotHeadId(): { [index in SlotKey]: string } {
    const map = {} as { [index in SlotKey]: string };
    for (const el of this.slotHeadElements) {
      for (const key of el.slots) {
        map[key] = el.id;
      }
    }
    return map;
  }

  @computed
  get displayScript() {
    let all = [...this.baseScript, ...this.addedElementMap.values()];
    if (!this.showArchived) {
      all = all.filter(el => !this.isArchived(el.id));
    }

    // apply edits so that sorting is correct after structural moves
    if (this.task === 'structural' || this.task === 'vocab') {
      for (const [index, el] of all.entries()) {
        const edit = this.activeEditsMap.get(el.id);
        if (edit) {
          all[index] = edit;
        }
      }
    }

    // this logic will guarantee the a chapter/passage break element exists for any
    // created chapter title / passage hint
    if (this.synthElementFunc) {
      // TODO factor to function because duplicating with neededSlotKeysList
      const availList: SlotKey[] = [];
      for (const element of all) {
        if (element.slots?.length > 0) {
          availList.push(...element.slots);
        }
      }
      const availSlots = new Set(availList);
      const withUnavail: ScriptElement[] = []; // elements with a dangling slot pointer
      for (const element of all) {
        if (!element.claimedSlot) {
          continue;
        }
        if (!availSlots.has(element.claimedSlot)) {
          withUnavail.push(element);
        }
      }
      const synthMap: Map<string, ScriptElement> = new Map();
      for (const element of withUnavail) {
        const synth = this.synthElementFunc(
          element,
          'ADD',
          this.effectiveTimestamp
        );
        if (synth) {
          synthMap.set(element.kind + element.reference, synth);
        }
      }
      const synthElements = [...synthMap.values()];
      all = [].concat(all, synthElements);
      untracked(() => {
        // untracked to avoid recompute reaction when computed return aleady incorporates synth elements
        for (const synth of synthElements) {
          this.addedElementMap.set(synth.id, synth);
        }
      });
    }

    all = sortScriptElements(all);
    // all = all.filter(el => el.kind !== 'SENTENCE');
    return all;
  }

  isArchived(id: string) {
    return this.activeArchivals.get(id);
  }

  toggleShowArchived() {
    if (this.isArchived(this.currentElementId)) {
      // todo: should probably fix things so we don't barf when there's no current element
      this.moveToSlotHead();
    }
    this.showArchived = !this.showArchived;
  }

  @computed
  get navigableScript() {
    if (!this.collapsed) {
      return this.displayScript.filter(el => !this.elementIsDecorator(el));
    } else {
      return this.displayScript.filter(el => this.elementIsOutput(el));
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  @computed
  get slotHeadElements() {
    return this.displayScript.filter(el => this.elementIsSlotHead(el));
  }

  @computed
  get outputKindScript() {
    return this.displayScript.filter(el => this.elementIsOutput(el));
  }

  @computed
  get elementIdToScriptIndex(): { [index in string]: number } {
    const indexLookup = {} as { [index in string]: number };
    for (const [index, element] of this.navigableScript.entries()) {
      indexLookup[element.id] = index;
    }
    return indexLookup;
  }

  setCurrentElementId(id: string) {
    if (!this.elementForId(id)) {
      return;
    }
    this.currentElementId = id;
  }

  // last candidate for selected reference element
  @computed
  get shouldBeVisibleCandidateId() {
    const currentElement = this.currentElement;
    if (this.elementIsOutput(currentElement)) {
      return currentElement.id;
    }
    if (this.elementIsSlotHead(currentElement)) {
      // TODO discuss proper behavior here
      const key = currentElement.slots[0];
      const candidates = this.outputKindScript.filter(
        el => el.claimedSlot === key
      );
      return candidates[candidates.length - 1]?.id;
      // if (this.hasWinner(currentElement.id)) {
      //   const winner = candidates.find(el => !this.isSuppressed(el.id));
      //   if (winner) {
      //     return winner.id;
      //   }
      // } else {
      //   const last = candidates[candidates.length - 1];
      //   if (last) {
      //     return last.id;
      //   }
      // }
    }
    return null;
  }

  toggleSuppressedElementId(id: string) {
    if (!this.elementIsOutput(this.elementForId(id))) {
      return;
    }
    this.activeSuppressions.toggle(id);
  }

  moveToNextLine() {
    const nextId = this.nextLineId;
    if (nextId) {
      this.setCurrentElementId(nextId);
    }
  }

  // todo: clean this up
  moveToNextReference() {
    for (;;) {
      const nextId = this.nextLineId;
      if (!nextId) {
        break;
      }
      this.setCurrentElementId(nextId);
      if (this.elementIsReference(this.currentElement)) {
        break;
      }
    }
  }

  get nextLineId() {
    let currentIndex = this.currentIndex;
    if (currentIndex === NO_INDEX) {
      return null;
    }
    currentIndex++;
    if (currentIndex >= this.navigableScript.length) {
      currentIndex = 0;
    }
    return this.navigableScript[currentIndex]?.id;
  }

  get nextRefLineId() {
    let currentIndex = this.currentIndex;
    if (currentIndex === NO_INDEX) {
      return null;
    }
    currentIndex++;
    if (currentIndex >= this.navigableScript.length) {
      return null;
    }
    return this.navigableScript[currentIndex]?.id;
  }

  moveToPrevLine() {
    let currentIndex = this.currentIndex;
    if (currentIndex === NO_INDEX) {
      return;
    }
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = this.navigableScript.length - 1;
    }
    this.setCurrentElementId(this.navigableScript[currentIndex].id);
  }

  // moves to the reference script element associated with currently selected element.
  // used before archiving
  moveToSlotHead() {
    const slotHeadId = this.slotHeadIdForCurrent();
    if (slotHeadId) {
      this.setCurrentElementId(slotHeadId);
    } else {
      // not sure if possible to not match a reference, but guarantee we move off current element
      this.moveToNextLine();
    }
  }

  // toggleSuppressed(id?: string) {
  //   if (id) {
  //     this.setCurrentElementId(id);
  //   }
  //   const currentElement = this.currentElement;
  //   if (this.isReference(currentElement)) {
  //     return;
  //   }
  //   this.activeSuppressions.toggle(currentElement.id);
  // }

  archive(id: string) {
    const element = this.elementForId(id);
    if (!this.elementIsOutput(element)) {
      return;
    }
    this.suppress(id);
    this.moveToSlotHead();
    this.activeArchivals.set(id, true);
  }

  unarchive(id: string) {
    this.activeArchivals.set(id, false);
  }

  toggleArchive() {
    const id = this.currentElementId;
    if (this.isArchived(id)) {
      this.unarchive(id);
    } else {
      this.archive(id);
    }
  }

  // async archiveCurrent() {
  //   const toBeArchivedId = this.currentElementId;
  //   const element = this.elementForId(toBeArchivedId);
  //   if (this.elementIsReference(element)) {
  //     return;
  //   }

  //   const kindLabel = element.kind.toLocaleLowerCase();
  //   const confirmed = await runSimpleConfirmation(
  //     `Delete selected ${kindLabel}?`
  //   );
  //   if (!confirmed) {
  //     return;
  //   }

  //   // avoid blowing up if current element can't be resolved
  //   this.moveToReference();
  //   this.archive(toBeArchivedId);
  // }

  suppress(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const currentElement = this.currentElement;
    if (!this.elementIsOutput(currentElement)) {
      return;
    }
    this.activeSuppressions.set(currentElement.id, true);
  }

  // overrideElement(element: ScriptElement, text: string) {
  //   if (element.origin === 'ADD' && this.addedElementMap.has(element.id)) {
  //     // TODO deal with timestamp scoping?
  //     const cloned = { ...element, text };
  //     // recomputeHashForElement(cloned);
  //     this.computeElementKeys(cloned, true);
  //     this.addedElementMap.set(element.id, cloned);
  //   } else {
  //     const cloned = { ...element, origin: 'EDIT' as const, text };
  //     // recomputeHashForElement(cloned);
  //     this.computeElementKeys(cloned, true);
  //     this.activeEditsMap.set(element.id, cloned);
  //   }
  // }

  overrideElementText(element: ScriptElement, text: string) {
    // this.overrideElement(element, text);
    this.overrideElementPartial(element, { text });
    this.pickWinner(element.id);
  }

  overrideElementReference(element: ScriptElement, reference: number) {
    if (!this.refToSentence[reference]) {
      alertError(`unexpected null sentence for reference ${reference}`);
      return;
    }
    // const cloned = { ...element, reference };
    // this.computeElementKeys(cloned, true);
    // this.activeEditsMap.set(element.id, cloned);
    this.overrideElementPartial(element, { reference });
  }

  overrideElementPartial(
    element: ScriptElement,
    partial: Partial<ScriptElement>
  ) {
    if (element.origin === 'ADD' && this.addedElementMap.has(element.id)) {
      // TODO deal with timestamp scoping?
      const cloned = { ...element, ...partial };
      this.computeElementKeys(cloned, true);
      this.addedElementMap.set(element.id, cloned);
    } else {
      const cloned = { ...element, origin: 'EDIT' as const, ...partial };
      this.computeElementKeys(cloned, true);
      this.activeEditsMap.set(element.id, cloned);
    }
  }

  // treat a click as 'pick winner' if there's a conflict or clicked element is already suppressed
  // otherwise just toggle
  toggleWinner(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    } else {
      id = this.currentElementId;
    }
    // TODO has pending is driving the need force signoff case vs has conflict or has missing
    if (this.activeSuppressions.get(id) || this.currentElementSlotKeyHasAlert) {
      this.pickWinner();
    } else {
      this.toggleSuppressedElementId(id);
    }
  }

  @computed
  get currentElementSlotKeyHasAlert() {
    const currentElement = this.currentElement;
    if (!currentElement || !currentElement.claimedSlot) {
      return false;
    }
    return this.alertSlotKeys.has(currentElement.claimedSlot);
  }

  // @computed
  // get currentElementHasConflict(): boolean {
  //   const currentElement = this.currentElement;
  //   if (!currentElement) {
  //     return false;
  //   }
  //   return this.conflictRefNumberSet.has(currentElement.reference);

  //   // if (this.elementIsReference(currentElement)) {
  //   //   return false;
  //   // }
  //   // const candidates = this.outputKindScript.filter(
  //   //   el =>
  //   //     el.reference === currentElement.reference &&
  //   //     !this.activeSuppressions.get(el.id)
  //   // );
  //   // return candidates.length > 1;
  // }

  pick(element: ScriptElement) {
    const key = element.claimedSlot;
    if (key) {
      const pick = { elementId: element.id, timestamp: epochSecondsFloat() };
      this.activePicks.set(key, pick);
    }
  }

  // handleEditIntent(id?: string) {
  //   if (this.projectIsStructural) {
  //     this.handleStructuralEditIntent(id);
  //   } else {
  //     this.handleTranslationEditIntent(id);
  //   }
  // }

  // when the user attempts to edit an element, we'll either clone a generated
  // candidate, or edit an added candidate
  // handleTranslationEditIntent(id?: string) {
  handleEditIntent(id?: string) {
    if (!id) {
      id = this.currentElementId;
    }

    let element = this.elementForId(id);
    // hitting 'enter' on the slot head element will be interpretted as first
    // selecting the last candidate
    if (!this.elementIsOutput(element) || this.elementIsSlotHead(element)) {
      if (this.projectIsStructural) {
        return; // no-op
      }
      id = this.shouldBeVisibleCandidateId;
      element = this.elementForId(id);
    }
    if (!element) {
      // alertError(`unexpected null element for id ${id}`);
      return;
    }
    if (element.origin === 'ADD' || !this.editIsAdd) {
      this.editElement(id);
    } else {
      this.addElement(element, element.text);
    }
  }

  editElement(id: string) {
    const element = this.elementForId(id);

    if (this.projectIsStructural) {
      this.setCurrentElementId(id);
      this.handleEditStructuralElement();
    } else {
      if (this.elementIsSlotHead(element)) {
        id = this.shouldBeVisibleCandidateId;
      }
      if (id) {
        this.editingElementId = id;
      }
    }
  }

  stopEditing() {
    this.editingElementId = null;
  }

  pickWinner(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const currentElement = this.currentElement;
    // if (this.referenceScript.includes(currentElement)) {
    if (!this.elementIsSlotClaiming(currentElement)) {
      return;
    }
    if (this.isArchived(currentElement.id)) {
      this.unarchive(currentElement.id);
    }
    const elements = this.outputKindScript.filter(
      el => el.claimedSlot === currentElement.claimedSlot
      //  && el.kind === currentElement.kind
    );
    runInAction(() => {
      for (const el of elements) {
        if (el.id !== currentElement.id) {
          this.activeSuppressions.set(el.id, true);
        } else {
          this.activeSuppressions.set(el.id, false);
        }
      }
      this.pick(currentElement);
    });
  }

  // handles desired flow if either a reference or output element is selected
  pickAndAdvance(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const element = this.currentElement;
    const isSlotClaiming = this.elementIsSlotClaiming(element);
    if (isSlotClaiming) {
      this.pickWinner(element.id);
      // this.moveToNextAlert();
      // this.moveToNextLine();
      this.moveToNextReference();
    } else {
      this.pickSoloCandidateAndAdvance(element);
    }
  }

  pickSoloCandidateAndAdvance(slotElement: ScriptElement) {
    if (!this.elementIsSlotHead(slotElement) || slotElement.slots.length > 1) {
      log.warn(
        'pickSoloCandidateAndAdvance - ignoring non slot head element or more than one slot'
      );
      return;
    }
    const slotKey = slotElement.slots[0];
    const elements = this.outputKindScript.filter(
      el => el.claimedSlot === slotKey
    );
    if (elements.length === 1) {
      this.pickWinner(elements[0].id);
      // this.moveToNextAlert();
      // this.moveToNextLine();
      this.moveToNextReference();
    } else {
      // todo: beep?
    }
  }

  togglePendingFlag(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const slotHeadId = this.slotHeadIdForCurrent();
    if (slotHeadId && !this.isPendingFlagged(slotHeadId)) {
      this.activePendingFlags.toggle(slotHeadId);
      if (this.isDeferFlagged(slotHeadId)) {
        // treat as mutually exclusive from masala level flag
        this.toggleDeferredFlag();
      }
    }
  }

  slotHeadIdForCurrent() {
    // const currentElement = this.currentElement;
    // if (this.referenceScript.includes(currentElement)) {
    //   return currentElement.id;
    // } else {
    //   return this.referenceToReferenceScriptElementId[currentElement.reference];
    // }
    return this.slotHeadIdForElement(this.currentElement);
  }

  // get currentReferenceElement() {
  //   const id = this.slotHeadIdForElement(this.currentElement);
  //   return this.elementForId(id);
  // }

  slotHeadIdForElement(element: ScriptElement) {
    if (!element) {
      return null;
    }
    if (this.elementIsSlotHead(element)) {
      return element.id;
    } else {
      return this.slotKeyToSlotHeadId[element.claimedSlot];
    }
  }

  isPendingFlagged(id: string) {
    return this.activePendingFlags.get(id);
  }

  toggleDeferredFlag(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const slotHeadId = this.slotHeadIdForCurrent();
    if (slotHeadId && !this.isDeferFlagged(slotHeadId)) {
      this.activeDeferredFlags.toggle(slotHeadId);
      if (this.isPendingFlagged(slotHeadId)) {
        // treat as mutually exclusive from masala level flag
        this.togglePendingFlag();
      }
    }
    // if (refElementId) {
    //   this.activeMasalaFlags.toggle(refElementId);
    //   if (
    //     this.isSamosaFlagged(refElementId) &&
    //     this.isMasalaFlagged(refElementId)
    //   ) {
    //     this.toggleSamosaFlag();
    //   }
    // }
  }

  isDeferFlagged(id: string) {
    return this.activeDeferredFlags.get(id);
  }

  isFlagged(id: string) {
    return this.isPendingFlagged(id) || this.isDeferFlagged(id);
  }

  @computed
  get currentIndex() {
    const currentElementId = this.currentElementId;
    if (!currentElementId) {
      return NO_INDEX;
    }
    return this.elementIdToScriptIndex[currentElementId];
  }

  @computed
  get currentElement() {
    // const currentIndex = this.currentIndex;
    // if (currentIndex === NO_INDEX) {
    //   return null;
    // }
    // return this.displayScript[currentIndex];
    return this.elementForId(this.currentElementId);
  }

  // beware, this doesn't currently work for the two-column view
  elementForId(id: string) {
    if (!id) {
      return null;
    }
    const index = this.elementIdToScriptIndex[id];
    if (index === NO_INDEX) {
      return null;
    }
    return this.navigableScript[index];
  }

  @computed
  get workingScript() {
    return this.outputKindScript.filter(
      el => !this.activeSuppressions.get(el.id)
    );
  }

  @computed
  get conflictSlotKeysList(): SlotKey[] {
    if (!this.canHaveConflicts) {
      return [];
    }
    return computeSlotConflicts(this.workingScript);
  }

  @computed
  get conflictSlotKeys(): Set<SlotKey> {
    return new Set(this.conflictSlotKeysList);
  }

  @computed
  get conflictSlotHeadIds(): Set<string> {
    return this.slotHeadIdsFromSlotKeys(this.conflictSlotKeysList);
  }

  @computed
  get allSlotKeyList(): SlotKey[] {
    const all: SlotKey[] = [];
    const script = this.displayScript;

    for (const element of script) {
      if (element.slots?.length > 0) {
        all.push(...element.slots);
      }
    }
    return all;
  }

  @computed
  get neededSlotKeysList(): SlotKey[] {
    const all = this.allSlotKeyList;
    if (this.task === 'structural') {
      // TODO revisit this hack to make summaries optional in structural task
      return all.filter(key => !key.includes('SUMMARY'));
    }
    return all;
  }

  @computed
  get neededSlotKeysSet(): Set<SlotKey> {
    return new Set(this.neededSlotKeysList);
  }

  @computed
  get missingSlotKeysSet(): Set<SlotKey> {
    if (!this.canHaveMissingReferences) {
      return new Set();
    }
    const have = new Set(this.workingScript.map(el => el.claimedSlot));
    const missing = minusSet(this.neededSlotKeysSet, have);
    return missing;
  }

  @computed({ keepAlive: true })
  get effectiveElementLintAlertIdSet(): Set<string> {
    if (!this.elementAlerts || this.elementAlerts.length === 0) {
      return new Set();
    }
    const activeAlertSuppressions = this.activeAlertSuppressions;
    const displayScript = this.displayScript;
    const displayElementMap = new Map(displayScript.map(el => [el.id, el]));
    const result = new Set<string>();
    for (const alert of this.elementAlerts) {
      if (displayElementMap.has(alert.elementId)) {
        const el = displayElementMap.get(alert.elementId);
        if (!alert.key.endsWith(el.hash)) {
          continue;
        }
        if (!activeAlertSuppressions.get(alert.key)) {
          result.add(alert.elementId);
        }
      }
    }
    return result;
  }

  @computed
  get alertSlotKeysList(): SlotKey[] {
    const missingSlotKeys = this.missingSlotKeysSet;
    const conflictSlotKeys = this.conflictSlotKeys;
    const activeSuppressions = this.activeSuppressions;
    const keys: SlotKey[] = [];
    const allSlotKeys = this.allSlotKeyList;
    const neededSlotKeys = this.neededSlotKeysList;
    for (const key of allSlotKeys) {
      if (missingSlotKeys.has(key) || conflictSlotKeys.has(key)) {
        keys.push(key);
        continue;
      }
      // const referenceElementId = this.referenceToReferenceScriptElementId[key];
      // TODO support no signoff mode
      if (this.requireSignoff) {
        if (!neededSlotKeys.includes(key)) {
          continue;
        }
        const signoff = this.activePicks.get(key);
        if (!signoff) {
          keys.push(key);
          continue;
        }
        const signoffElementId = signoff.elementId;
        if (activeSuppressions.get(signoffElementId)) {
          keys.push(key);
        }
      }
    }
    if (this.effectiveElementLintAlertIdSet.size > 0) {
      keys.push(...this.effectiveElementLintAlertIdSet);
    }
    return keys;
  }

  slotHeadIdsFromSlotKeys(keys: SlotKey[]): Set<string> {
    const headIds = keys.map(key => this.slotKeyToSlotHeadId[key]);
    return new Set(headIds);
  }

  @computed
  get alertSlotKeys(): Set<SlotKey> {
    return new Set(this.alertSlotKeysList);
  }

  @computed
  get alertSlotHeadIds(): Set<string> {
    return this.slotHeadIdsFromSlotKeys(this.alertSlotKeysList);
  }

  @computed
  get pendingIndexes(): number[] {
    return this.indexesFromElementIds(this.alertSlotHeadIds);
  }

  @computed
  get pendingCount(): number {
    return this.pendingIndexes.length;
  }

  @computed
  get samosaFlaggedCount() {
    // return this.referenceScript.reduce(
    //   (acc, element) => (this.isSamosaFlagged(element.id) ? acc + 1 : acc),
    //   0
    // );
    return this.pendingFlaggedElementIds.length;
  }

  @computed
  get pendingFlaggedElementIds(): string[] {
    const all = this.slotHeadElements
      .filter(el => this.isPendingFlagged(el.id))
      .map(el => el.id);
    return all;
  }

  // @computed
  // get deferredFlaggedCount() {
  //   return this.referenceScript.reduce(
  //     (acc, element) => (this.isMasalaFlagged(element.id) ? acc + 1 : acc),
  //     0
  //   );
  // }

  @computed
  get pendingSlotHeadIds(): Set<string> {
    let all: SlotKey[];
    // TODO
    all = [
      // ...this.missingRefNumbers,
      // ...this.conflictRefNumbers,
      ...this.alertSlotHeadIds,
      ...this.pendingFlaggedElementIds,
    ];
    // const intArray = Int32Array.from(all);
    // intArray.sort();
    // return intArray;
    return new Set(all);
  }

  indexesFromElementIds(keys: Set<string>): number[] {
    // assumes element ids are unique
    const result = new Int32Array(keys.size);
    const indexLookup = this.elementIdToScriptIndex;
    for (const [i, id] of [...keys.values()].entries()) {
      const index = indexLookup[id];
      result[i] = index;
    }
    result.sort();
    return result as unknown as number[];
  }

  @computed
  get pendingSlotHeadIndexes(): number[] {
    const slotHeadIds = this.pendingSlotHeadIds;
    return this.indexesFromElementIds(slotHeadIds);
  }

  @computed
  get outputSlotHeadIndexes(): number[] {
    const slotHeadIds = this.outputSlotHeadIds;
    return this.indexesFromElementIds(slotHeadIds);
  }

  @computed
  get outputSlotKeysList(): SlotKey[] {
    const keys: SlotKey[] = [];
    for (const element of this.outputKindScript) {
      if (element.claimedSlot) {
        keys.push(element.claimedSlot);
      }
    }
    return keys;
  }

  @computed
  get outputSlotKeys(): Set<SlotKey> {
    return new Set(this.outputSlotKeysList);
  }

  @computed
  get outputSlotHeadIds(): Set<string> {
    const keys = this.outputSlotKeysList;
    const headIds = keys.map(key => this.slotKeyToSlotHeadId[key]);
    return new Set(headIds);
  }

  @computed
  get nextAlertIndex(): number {
    // const alertIndexes = this.activeAlertIndexes;
    // const currentIndex = this.currentIndex;
    // if (currentIndex === NO_INDEX || !alertIndexes.length) {
    //   return NO_INDEX;
    // }
    // let idx0 = searchSorted(alertIndexes, currentIndex);
    // console.log('idx: ' + idx0);
    // if (idx0 >= alertIndexes.length) {
    //   return alertIndexes[0];
    // }
    // return alertIndexes[idx0];
    return this.nextIndex(this.pendingSlotHeadIndexes);
  }

  nextIndex(indexes: number[]): number {
    const currentIndex = this.currentIndex;
    if (currentIndex === NO_INDEX || !indexes.length) {
      return NO_INDEX;
    }
    let idx0 = searchSorted(indexes, currentIndex);
    console.log('idx: ' + idx0);
    if (idx0 >= indexes.length) {
      return indexes[0];
    }
    return indexes[idx0];
  }

  @computed
  get prevAlertIndex(): number {
    // const alertIndexes = this.activeAlertIndexes;
    // const currentIndex = this.currentIndex;
    // if (currentIndex === NO_INDEX || !alertIndexes.length) {
    //   return NO_INDEX;
    // }
    // let idx0 = searchSorted(alertIndexes, currentIndex);
    // if (idx0 > 0) {
    //   idx0--;
    // }
    // const valueFound = alertIndexes[idx0];
    // if (valueFound === currentIndex) {
    //   idx0--;
    // }
    // if (idx0 < 0) {
    //   return alertIndexes.at(-1);
    // }
    // return alertIndexes[idx0];
    return this.prevIndex(this.pendingSlotHeadIndexes);
  }

  prevIndex(indexes: number[]): number {
    const currentIndex = this.currentIndex;
    if (currentIndex === NO_INDEX || !indexes.length) {
      return NO_INDEX;
    }
    let idx0 = searchSorted(indexes, currentIndex);
    if (idx0 > 0) {
      idx0--;
    }
    const valueFound = indexes[idx0];
    if (valueFound === currentIndex) {
      idx0--;
    }
    if (idx0 < 0) {
      return indexes.at(-1);
    }
    return indexes[idx0];
  }

  @computed
  get nextAlertElementId() {
    const nextAlertIndex = this.nextAlertIndex;
    if (nextAlertIndex === NO_INDEX) {
      return null;
    }
    return this.navigableScript[nextAlertIndex].id;
  }

  @computed
  get prevAlertElementId() {
    const prevAlertIndex = this.prevAlertIndex;
    if (prevAlertIndex === NO_INDEX) {
      return null;
    }
    return this.navigableScript[prevAlertIndex].id;
  }

  moveToNextAlert() {
    const nextAlertElementId = this.nextAlertElementId;
    if (!nextAlertElementId) {
      return;
    }
    this.setCurrentElementId(nextAlertElementId);
  }

  moveToPrevAlert() {
    const prevAlertElementId = this.prevAlertElementId;
    if (!prevAlertElementId) {
      return;
    }
    this.setCurrentElementId(prevAlertElementId);
  }

  moveToNextOutputSlotHead() {
    const elementId = this.nextOutputSlotHeadId;
    if (!elementId) {
      return;
    }
    this.setCurrentElementId(elementId);
  }

  moveToPrevOutputSlotHead() {
    const elementId = this.prevOutputSlotsHeadId;
    if (!elementId) {
      return;
    }
    this.setCurrentElementId(elementId);
  }

  // @computed
  get nextOutputSlotHeadId() {
    const index = this.nextIndex(this.outputSlotHeadIndexes);
    if (index === NO_INDEX) {
      return null;
    }
    return this.navigableScript[index].id;
  }

  // @computed
  get prevOutputSlotsHeadId() {
    const index = this.prevIndex(this.outputSlotHeadIndexes);
    if (index === NO_INDEX) {
      return null;
    }
    return this.navigableScript[index].id;
  }

  @computed
  get currentAlertNumber() {
    const currentIndex = this.currentIndex;
    if (currentIndex === NO_INDEX) {
      return NO_INDEX;
    }
    const alertIndexes = this.pendingSlotHeadIndexes;
    let idx = searchSorted(alertIndexes, currentIndex);
    if (idx > 0) {
      idx--;
    }
    const foundValue = alertIndexes[idx];
    if (foundValue === currentIndex) {
      return idx;
    }
    return NO_INDEX;
  }

  elementIdHasAlert(id: string) {
    const elementIndex = this.elementIdToScriptIndex[id];
    if (typeof elementIndex === 'undefined') {
      return false;
    }
    return sortedHas(this.pendingSlotHeadIndexes, elementIndex);
  }

  isSuppressed(id: string): boolean {
    return this.activeSuppressions.get(id);
  }

  isLoser(id: string): boolean {
    if (this.disablePick) {
      return false;
    }
    return this.isSuppressed(id) && this.hasWinnerForSlotOf(id);
  }

  isPicked(id: string): boolean {
    if (this.disablePick) {
      return false;
    }
    if (this.isSuppressed(id)) {
      return false;
    }
    const element = this.elementForId(id);
    if (!element) {
      log.error('unexpected null element for id ' + id);
      return false;
    }
    if (!this.elementIsSlotClaiming(element)) {
      return false;
    }
    const key = element.claimedSlot;
    const pick = this.activePicks.get(key);
    return pick?.elementId === id;
  }

  hasSelectedSlotHead(id: string): boolean {
    const selectedSlotHeadId = this.slotHeadIdForCurrent();

    const element = this.elementForId(id);
    const elementSlotHeadId = this.slotHeadIdForElement(element);
    const result = selectedSlotHeadId === elementSlotHeadId;
    return result;
  }

  hasWinnerForSlotOf(id: string): boolean {
    if (this.disablePick) {
      return false;
    }
    const element = this.elementForId(id);
    const key = element.claimedSlot;
    return !this.missingSlotKeysSet.has(key);
    // const winners = this.outputKindScript.filter(
    //   el => el.slotKey === refElement.slotKey && !this.isSuppressed(el.id)
    // );
    // return winners.length >= 1;
  }

  // addedForReferenceElement(refElement: ScriptElement): ScriptElement {
  //   // const refElement = this.elementForId(id);
  //   const result = this.outputKindScript.find(
  //     el => el.reference === refElement.reference && el.origin === 'ADD'
  //   );
  //   return result;
  // }

  hasNote(id: string): boolean {
    return !!this.activeNotes.get(id);
  }

  async editNote(id?: string) {
    if (this.editingANote) {
      return;
    }

    if (id) {
      this.setCurrentElementId(id);
    }

    // const currentElement = this.currentElement;
    let nonWorkElementScope = false;
    let refElementId = this.slotHeadIdForCurrent();
    if (!refElementId) {
      const currentElement = this.currentElement;
      if (this.elementIsReference(currentElement)) {
        refElementId = currentElement.id;
        nonWorkElementScope = true;
      }
    }
    const currentNote = this.activeNotes.get(refElementId) ?? '';
    this.editingANote = true;
    const edit = await runTextFormModal({
      label: 'Edit note',
      text: currentNote,
      type: 'textarea',
      size: 'wide',
    });
    this.editingANote = false;

    if (edit === undefined) {
      // cancel flow
      return;
    }
    if (edit === currentNote) {
      return;
    }
    if (edit === '') {
      // this.deleteNote(refElementId);
      this.activeNotes.set(refElementId, ''); // @jason, please implement a cleaner delete api
      // this.markResolved(refElementId);
      this.activePendingFlags.set(refElementId, false);
      this.activeDeferredFlags.set(refElementId, false);
    } else {
      this.activeNotes.set(refElementId, edit);
      if (nonWorkElementScope) {
        this.activeDeferredFlags.set(refElementId, true);
      } else {
        if (!this.isFlagged(refElementId)) {
          this.activePendingFlags.set(refElementId, true);
          this.activeDeferredFlags.set(refElementId, false);
        }
      }
    }
  }

  markPending(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const slotHeadId = this.slotHeadIdForCurrent();
    // const currentNote = this.activeNotes.get(refElementId) ?? '';
    // if (!currentNote) {
    //   this.activeNotes.set(refElementId, 'to-do');
    // }

    if (slotHeadId) {
      this.activePendingFlags.set(slotHeadId, true);
      this.activeDeferredFlags.set(slotHeadId, false);
    }
  }

  markDeferred(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    const slotHeadId = this.slotHeadIdForCurrent();
    if (slotHeadId) {
      this.activePendingFlags.set(slotHeadId, false);
      this.activeDeferredFlags.set(slotHeadId, true);
    }
  }

  markResolved(id?: string) {
    if (id) {
      this.setCurrentElementId(id);
    }
    let refElementId = this.slotHeadIdForCurrent();
    if (!refElementId) {
      const currentElement = this.currentElement;
      if (this.elementIsReference(currentElement)) {
        refElementId = currentElement.id;
      }
    }

    if (refElementId) {
      const currentNote = this.activeNotes.get(refElementId) ?? '';
      if (currentNote === 'to-do') {
        this.activeNotes.set(refElementId, '');
      }
      this.activePendingFlags.set(refElementId, false);
      this.activeDeferredFlags.set(refElementId, false);
    }
  }

  deleteNote(id: string) {
    // TODO factor with markResolved
    this.setCurrentElementId(id);
    let refElementId = this.slotHeadIdForCurrent();
    if (!refElementId) {
      const currentElement = this.currentElement;
      if (this.elementIsReference(currentElement)) {
        refElementId = currentElement.id;
      }
    }

    if (refElementId) {
      this.activeNotes.set(refElementId, '');
      this.activePendingFlags.set(refElementId, false);
      this.activeDeferredFlags.set(refElementId, false);
    }
  }

  addElementWithText(
    refElement: ScriptElement,
    text: string,
    kind?: ScriptElementKind
  ) {
    // const currentElement = this.currentElement;
    // if (!currentElement) {
    //   return;
    // }
    const element: ScriptElement = {
      ...refElement,
      id: randomString(12),
      text,
      origin: 'ADD',
      timestamp: this.effectiveTimestamp,
      ...this.addElementPrototype,
      kind,
    };
    // recomputeHashForElement(element);
    this.computeElementKeys(element, true);
    this.addedElementMap.set(element.id, element);
    this.setCurrentElementId(element.id);
    this.pickWinner(element.id);
  }

  addBreakElement(reference: number, kind: 'PASSAGE_BREAK' | 'CHAPTER_BREAK') {
    const synth: ScriptElement = {
      id: null,
      kind,
      origin: 'ADD',
      reference,
      text: '',
      hash: null,
      claimedSlot: null,
      slots: null,
      groupKey: null,
      timestamp: epochSeconds(),
    };
    structuralTaskComputeElementKeys(synth);
    this.addedElementMap.set(synth.id, synth);
  }

  // this logic would restrict creation of additional added elements for one reference element
  // async addOrEditElement(id?: string) {
  //   if (!id) {
  //     id = this.currentElementId;
  //   }
  //   const element = this.elementForId(id);
  //   const refElementId = this.referenceElementIdForElement(element);
  //   const refElement = this.elementForId(refElementId);
  //   const existing = this.addedForReferenceElement(refElement);
  //   if (existing) {
  //     this.setCurrentElementId(existing.id);
  //     this.editElement(existing.id);
  //   } else {
  //     this.setCurrentElementId(refElementId);
  //     this.addElement(refElement);
  //   }
  // }

  async handleAddTranslation(id?: string, defaultText?: string) {
    if (!id) {
      id = this.currentElementId;
    }
    const element = this.elementForId(id);
    if (!element) {
      return;
    }
    const slotHeadId = this.slotHeadIdForElement(element);
    const slotElement = this.elementForId(slotHeadId);
    await this.addElement(slotElement, defaultText);
  }

  async handleAddVocab(id?: string, defaultText?: string) {
    if (!id) {
      id = this.currentElementId;
    }
    let selected = window.getSelection().toString();
    if (selected && !defaultText && selected.length < 100) {
      defaultText = selected;
    }
    const element = this.elementForId(id);
    if (!element) {
      return;
    }
    if (element.kind === 'SENTENCE' && defaultText) {
      if (
        element.text.startsWith(defaultText) &&
        onlyFirstCapitalized(defaultText)
      ) {
        defaultText = defaultText.toLocaleLowerCase();
      }
    }
    const cloned = { ...element };
    cloned.kind = 'VOCAB';
    await this.addElement(cloned, defaultText);
  }

  async addElement(refElement: ScriptElement, defaultText?: string) {
    // if (id) {
    //   this.setCurrentElementId(id);
    // }
    const addKind =
      this.task === 'translation' ? 'TRANSLATION' : refElement.kind;
    const kindLabel = addKind.toLowerCase();
    const text = await runTextFormModal({
      label: `Add ${kindLabel}`,
      text: defaultText ?? '',
      type: 'textarea',
      size: 'wide',
    });
    if (!text) {
      return;
    }
    this.addElementWithText(refElement, text, addKind);
  }

  // async handleAddPassage(refElement?: ScriptElement, defaultText?: string) {
  // if (!refElement) {
  //   refElement = this.currentReferenceElement;
  // }
  async handleAddPassage(defaultText?: string) {
    const anchorElement = this.currentElement;
    if (!anchorElement?.reference) {
      alertError('invalid selection');
      return;
    }
    const text = await runTextFormModal({
      label: 'Add passage',
      text: defaultText ?? '',
      type: 'textarea',
      size: 'wide',
    });
    if (!text) {
      return;
    }
    this.addElementWithText(anchorElement, text, 'PASSAGE');
    // this.addPassageWithText(reference, text);
    // this.addPassageWithText(anchorElement, text);
  }

  // async handleStructuralEditIntent(id?: string) {
  //   if (!id) {
  //     id = this.currentElementId;
  //   }
  //   const element = this.elementForId(id);
  //   if (!element) {
  //     return;
  //   }
  //   if (this.elementIsReference(element)) {
  //     return;
  //   }
  //   this.editElement(id);
  // }

  async handleEditStructuralElement() {
    const element = this.currentElement;

    const text = await runTextFormModal({
      label: 'Edit element',
      text: element.text,
      type: 'textarea',
      size: 'wide',
    });
    if (!text) {
      return;
    }
    this.overrideElementText(element, text);
  }

  async handleAddChapter(defaultText?: string) {
    const anchorElement = this.currentElement;
    if (!anchorElement?.reference) {
      alertError('invalid selection');
      return;
    }
    const text = await runTextFormModal({
      label: 'Add chapter',
      text: defaultText ?? '',
      type: 'textarea',
      size: 'wide',
    });
    if (!text) {
      return;
    }
    this.addElementWithText(anchorElement, text, 'CHAPTER');
  }

  async handleAddChapterBreak() {
    const reference = this.currentElement?.reference;
    if (!reference) {
      alertError('invalid selection');
      return;
    }

    const count = this.priorChapterCount(reference);
    if (count === 0 && reference > 1) {
      // need to autocreate an initial chapter break
      await this.addChapterBreak(1);
    }
    this.addChapterBreak(reference);
  }

  async addChapterBreak(reference: number) {
    // const count = this.priorChapterCount(refElement);
    // const text = `CHAPTER ${count + 1}`;
    // this.addElementWithText(refElement, text, 'CHAPTER');
    this.addBreakElement(reference, 'CHAPTER_BREAK');
  }

  async handleAddPassageMarker() {
    const reference = this.currentElement?.reference;
    if (!reference) {
      alertError('invalid selection');
      return;
    }
    this.addPassageMarker(reference);
  }

  async addPassageMarker(reference: number) {
    this.addBreakElement(reference, 'PASSAGE_BREAK');
  }

  async handleMoveVocab() {
    // TODO factor with below
    const element = this.currentElement;
    if (!element) {
      return;
    }
    if (this.elementIsReference(element)) {
      return;
    }
    const lineStr = await runTextFormModal({
      label: 'Move to transcript line number',
      text: String(element.reference),
      // type: 'textarea',
      // size: 'wide',
    });
    if (!lineStr) {
      return;
    }
    const newRef = Number(lineStr);
    if (isNaN(newRef)) {
      alertError('invalid line number');
      return;
    }
    this.overrideElementReference(element, newRef);
  }

  async handleMoveStructural() {
    const element = this.currentElement;
    if (!element) {
      return;
    }
    if (this.elementIsReference(element)) {
      return;
    }
    const lineStr = await runTextFormModal({
      label: 'Move to transcript line number',
      text: String(element.reference),
      // type: 'textarea',
      // size: 'wide',
    });
    if (!lineStr) {
      return;
    }
    const newRef = Number(lineStr);
    if (isNaN(newRef)) {
      alertError('invalid line number');
      return;
    }

    const starts = element.kind.slice(0, 5);
    // update all output elements matching the source reference line
    const elements = this.outputKindScript.filter(
      el => el.reference === element.reference && el.kind.startsWith(starts)
    );
    runInAction(() => {
      for (const el of elements) {
        this.overrideElementReference(el, newRef);
      }
    });
  }

  priorChapterCount(reference: number): number {
    const elements = this.outputKindScript.filter(
      el => el.reference < reference && el.kind === 'CHAPTER_BREAK'
    );
    return elements.length;
  }

  // draft a prompt to generate a title and summary for current chapter
  // injectChapterInfoPrompt() {
  //   const selectedRef = this.currentElement.reference;
  //   const chapterStartRefs = uniq(
  //     this.outputKindScript
  //       .filter(el => el.kind === 'CHAPTER_BREAK')
  //       .map(el => el.reference)
  //   );
  //   if (chapterStartRefs.length === 0) {
  //     alertError('chapter markers not found');
  //     return;
  //   }

  //   // let prevChapterRefId = chapterRefIds[0];
  //   // let nextChapterRefId = chapterRefIds[1];
  //   let prevChapterIndex: number = 0;
  //   for (let i = 1; i <= chapterStartRefs.length; i++) {
  //     if (!chapterStartRefs[i] || chapterStartRefs[i] > selectedRef) {
  //       prevChapterIndex = i - 1;
  //       break;
  //     }
  //   }
  //   const chapterStartRef = chapterStartRefs[prevChapterIndex];
  //   let chapterEndRef: number;
  //   const nextChapterStartRef = chapterStartRefs[prevChapterIndex + 1];
  //   if (nextChapterStartRef) {
  //     chapterEndRef = chapterStartRefs[prevChapterIndex + 1] - 1;
  //   } else {
  //     chapterEndRef =
  //       this.outputKindScript[this.outputKindScript.length - 1].reference;
  //   }
  //   const chapterNum = String(prevChapterIndex + 1);
  //   const lineRange = `${chapterStartRef}-${chapterEndRef}`;

  //   log.info(
  //     `injectChapterInfoPrompt - chapterNum: ${chapterNum}, range: ${lineRange}`
  //   );

  //   getSamosaModel().injectChapterInfoPrompt({ chapterNum, lineRange });
  //   // todo: close merge view modal and/or submit
  // }
  injectChapterInfoPrompt() {
    const params = this.infoPromptInjectParams(
      this.currentElement.reference,
      'CHAPTER'
    );
    getSamosaModel().injectChapterInfoPrompt(params);
  }

  injectPassageInfoPrompt() {
    const params = this.infoPromptInjectParams(
      this.currentElement.reference,
      'PASSAGE'
    );
    getSamosaModel().injectPassageInfoPrompt(params);
  }

  injectTranslateLinePrompt() {
    const lineNumber = this.currentElement.reference;
    getSamosaModel().injectTranslateLinePrompt(lineNumber.toString());
  }

  infoPromptInjectParams(selectedRef: number, kind: 'CHAPTER' | 'PASSAGE') {
    const startRefs = uniq(
      this.outputKindScript
        .filter(el => el.kind === `${kind}_BREAK`)
        .map(el => el.reference)
    );
    if (startRefs.length === 0) {
      alertError('markers not found');
      return;
    }

    let prevIndex: number = 0;
    for (let i = 1; i <= startRefs.length; i++) {
      if (!startRefs[i] || startRefs[i] > selectedRef) {
        prevIndex = i - 1;
        break;
      }
    }
    const startRef = startRefs[prevIndex];
    let endRef: number;
    const nextStartRef = startRefs[prevIndex + 1];
    if (nextStartRef) {
      endRef = startRefs[prevIndex + 1] - 1;
    } else {
      endRef =
        this.outputKindScript[this.outputKindScript.length - 1].reference;
    }
    const num = String(prevIndex + 1);
    const lineRange = `${startRef}-${endRef}`;

    log.info(`injectInfoPrompt - num: ${num}, range: ${lineRange}`);

    return { num, lineRange };
  }

  // addElementKindWithText(anchorElement: ScriptElement, text: string, kind: ) {
  //   const element: ScriptElement = {
  //     ...anchorElement,
  //     id: randomString(12),
  //     text,
  //     kind: 'PASSAGE',
  //     origin: 'ADD',
  //     timestamp: this.effectiveTimestamp,
  //     // ...this.addElementPrototype,
  //   };
  //   recomputeHashForElement(element);
  //   this.addedElementMap.set(element.id, element);
  //   this.setCurrentElementId(element.id);
  //   this.pickWinner(element.id);
  // }

  seekToElement(el: ScriptElement) {
    if (!el || !this.player || !this.refNumberToTimes) {
      return;
    }
    const interval = this.refNumberToTimes[el.reference];
    if (!interval) {
      return;
    }
    this.player.seek(interval.begin);
  }

  playFromCurrentElement() {
    this.seekToElement(this.currentElement);
    this.player?.play();
  }

  @computed({ keepAlive: true })
  get sentenceTracker() {
    const player = this.player;
    const sentenceTimes = this.sentenceTimes;
    if (!(player && sentenceTimes)) {
      return null;
    }
    const transportState = this.player.audioTransport.transportState;
    const sentences = this.referenceScript.filter(el => el.kind === 'SENTENCE');
    const sentencesWithTimes = sentences.filter(el => !!sentenceTimes[el.id]);

    const sentenceTimeIntervals = fromIntervals(
      sentencesWithTimes.map(sentence => sentenceTimes[sentence.id])
    );

    const tracker = CreateTracker({
      elements: sentencesWithTimes.map(el => el.id),
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => transportState.audioPosition,
      intervals: sentenceTimeIntervals,
    });

    return tracker;
  }

  @computed
  get currentPlayingSentenceId(): string {
    if (!this.player?.transportState.isPlaying) {
      return null;
    }
    return this.sentenceTracker?.observableIsUnder();
  }

  @computed({ keepAlive: true })
  get passageBreakRefs(): number[] {
    const breaks = this.outputKindScript.filter(
      el => el.kind === 'PASSAGE_BREAK'
    );
    return breaks.map(el => el.reference);
  }

  @computed({ keepAlive: true })
  get chapterBreakRefs(): number[] {
    const breaks = this.outputKindScript.filter(
      el => el.kind === 'CHAPTER_BREAK'
    );
    return breaks.map(el => el.reference);
  }

  getTimeDuration(element: ScriptElement, kindRefs: number[]) {
    if (!this.refNumberToTimes) {
      return null;
    }
    const ref = element.reference;
    const refs = kindRefs;
    const refTimes = this.refNumberToTimes;
    const nextIdx = searchSorted(refs, ref);
    let nextRef = 0;
    if (nextIdx < refs.length) {
      nextRef = refs[nextIdx];
    }
    // avoid fatal barf with unexpected data (was breaking here when moving to an invalid line)
    const startTime = refTimes[ref]?.begin || 0;
    const endTime = nextRef ? refTimes[nextRef]?.begin : this.endTime || 0;
    const duration = endTime - startTime;
    return [startTime, duration];
  }

  getPassageBreakTimeDuration(element: ScriptElement) {
    return this.getTimeDuration(element, this.passageBreakRefs);
  }

  getChapterBreakTimeDuration(element: ScriptElement) {
    return this.getTimeDuration(element, this.chapterBreakRefs);
  }

  getOptionalElementTimeDuration(element: ScriptElement) {
    if (!this.projectIsStructural) {
      return null;
    }
    switch (element.kind) {
      case 'PASSAGE_BREAK':
        return this.getPassageBreakTimeDuration(element);
      case 'CHAPTER_BREAK':
        return this.getChapterBreakTimeDuration(element);
      default:
        return null;
    }
  }

  handlePlayAction() {
    if (!this.player) {
      return;
    }
    if (this.player.transportState.isPlaying) {
      this.player.pause();
    } else {
      this.playFromCurrentElement();
    }
  }

  handleRewindAction() {
    if (!this.player) {
      return;
    }
    if (!this.player.transportState.isPlaying) {
      this.seekToElement(this.currentElement);
    }
    let targetTime = this.player.audioTransport.audioPosition - 5000;
    if (targetTime < 0) {
      targetTime = 0;
    }
    this.player.seek(targetTime);
    if (!this.player.transportState.isPlaying) {
      this.player.play();
    }
  }

  suppressLintAlerts(id: string) {
    const elementAlerts = this.elementAlerts;
    const alerts = elementAlerts.filter(alert => alert.elementId === id);
    if (!alerts.length) {
      return;
    }
    runInAction(() => {
      for (const alert of alerts) {
        this.activeAlertSuppressions.set(alert.key, true);
      }
    });
  }

  suppressAlertsForCurrent() {
    const element = this.currentElement;
    if (!element) {
      return;
    }
    this.suppressLintAlerts(element.id);
  }

  get elementFlags() {
    const flags = Object.fromEntries(this.elementFlagsEditsMap.toJSON());
    return flags;
  }

  get edits() {
    const edits = Object.fromEntries(this.activeEditsMap.toJSON());
    return edits;
  }

  get addedElements() {
    const addedElements = Object.fromEntries(this.addedElementMap.toJSON());
    return addedElements;
  }

  get notes() {
    const notes = Object.fromEntries(this.notesEditsMap.toJSON());
    return notes;
  }

  toggleShowNotes() {
    this.showNotes = !this.showNotes;
  }

  toggleShowLosers() {
    this.showLosers = !this.showLosers;
  }

  get signoffs() {
    const signoffs = Object.fromEntries(this.picksEditsMap.toJSON());
    return signoffs;
  }

  get alertSuppressions() {
    const alertSuppressions = Object.fromEntries(
      this.alertSuppressionsEditsMap.toJSON()
    );
    return alertSuppressions;
  }

  @computed
  get dirty() {
    return (
      this.elementFlagsEditsMap.size ||
      this.activeEditsMap.size ||
      this.addedElementMap.size ||
      this.notesEditsMap.size ||
      this.alertSuppressionsEditsMap.size
    );
  }

  async saveToMasala(): Promise<void> {
    if (!this.enableMerge) {
      window.alert('merge not enabled for this view');
      return;
    }
    const incompleteCount = this.pendingCount;
    const samosaFlaggedCount = this.samosaFlaggedCount;
    const totalPending = incompleteCount + samosaFlaggedCount;
    if (totalPending !== 0) {
      window.alert("can't merge with pending items");
      return;
    }
    const confirmed = await runSimpleConfirmation(
      'Merge content back to Masala?'
    );
    if (!confirmed) {
      return;
    }
    const model = getSamosaModel();
    await model.saveToMasala(this);
  }

  // run() {
  //   return openModal(TranslationMergeModal, this);
  // }
}

interface TouchupMap<T> {
  get(id: string): T;
  set(id: string, value: T): void;
}

export function sortedHas(arr: number[], value: number) {
  let idx = searchSorted(arr, value);
  if (idx > 0) {
    idx--;
  }
  const valueFound = arr[idx];
  return valueFound === value;
}

class TouchupBuffer<T> implements TouchupMap<T> {
  edits: Map<string, T> | ObservableMap<string, T>;
  base: { [index in string]: T };

  constructor(
    edits: Map<string, T> | ObservableMap<string, T>,
    base: { [index in string]: T }
  ) {
    this.edits = edits;
    this.base = base;
  }
  get(id: string): T {
    const editValue = this.edits.get(id);
    if (editValue !== undefined) {
      return editValue;
    }
    return this.base[id];
  }
  set(id: string, value: T): void {
    this.edits.set(id, value);
  }
}

class BooleanTouchupBuffer extends TouchupBuffer<boolean> {
  toggle(id: string): void {
    this.edits.set(id, !this.get(id));
  }
}

class FlagsTouchupBuffer {
  edits: Map<string, number> | ObservableMap<string, number>;
  base: { [index in string]: number };
  flag: number;
  constructor(
    edits: Map<string, number> | ObservableMap<string, number>,
    base: { [index in string]: number },
    flag: number
  ) {
    this.edits = edits;
    this.base = base;
    this.flag = flag;
  }

  get(id: string): boolean {
    const editValue = this.edits.get(id);
    if (editValue !== undefined) {
      return getFlag(editValue, this.flag);
    }
    return getFlag(this.base[id], this.flag);
  }

  set(id: string, value: boolean): void {
    const editValue = this.edits.get(id) ?? this.base[id];
    const newValue = setFlag(editValue, this.flag, value);
    this.edits.set(id, newValue);
  }

  toggle(id: string): void {
    const editValue = this.edits.get(id) ?? this.base[id];
    const newValue = toggleFlag(editValue, this.flag);
    this.edits.set(id, newValue);
  }
}
