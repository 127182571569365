import * as React from 'react';
import {
  getSamosaModel,
  useSamosaModel,
} from '../../models/samosa-model-handle';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { IconButton } from '@naan/primitives/button';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { observer } from 'mobx-react';
import { ArchiveIcon } from '@console/components/icons/archive-icon';
import { InfoCircleSmallIcon } from '@naan/icons/info-circle-icon';
import { MergeIcon } from '../components/icons';
import { EditSmallIcon } from '@naan/icons/edit-icon';
import { ProjectInfoModal } from './project-metadata-form';
import { alertError } from 'ui/misc-utils';
import { DialogPresenter } from '../dialog-presenter';
import { runTextFormModal } from '../components/text-form-modal';
import { BsThreeDotsVertical, BsToggle2On } from 'react-icons/bs';
import { runSimpleConfirmation } from '../components/simple-confirm';
import { Auth } from '@masala-lib/editorial/db/auth';
// import { createLogger } from '@app/logger';
// const log = createLogger('project-name-form');

export const handleMergeImportBackToUnit = async () => {
  const model = getSamosaModel();
  // const auth = Auth.getInstance();
  // if (!auth.appUser.isSuperAdmin && model.task === 'vocab') {
  //   alertError(
  //     'Import to script-editor is WIP and currently restricted for vocab projects'
  //   );
  //   return;
  // }

  try {
    const prompt =
      'This will merge samosa content with existing in script editor. Proceed?';
    const confirmed = await runSimpleConfirmation(prompt);
    if (confirmed) {
      await model.importBackToUnit(true);
    }
  } catch (error) {
    alertError(error);
  }
};

export const handleOverwriteImportBackToUnit = async () => {
  const model = getSamosaModel();
  // const auth = Auth.getInstance();
  // if (!auth.appUser.isSuperAdmin && model.task === 'vocab') {
  //   alertError(
  //     'Import to script-editor is WIP and currently restricted for vocab projects'
  //   );
  //   return;
  // }

  try {
    const prompt = `This will overwrite existing script editor ${model.task} content with samosa content. Proceed?`;
    const confirmed = await runSimpleConfirmation(prompt);
    if (confirmed) {
      await model.importBackToUnit(false);
    }
  } catch (error) {
    alertError(error);
  }
};

export const ProjectOverflowMenu = observer(() => {
  const auth = Auth.getInstance();
  const model = useSamosaModel();
  const showProjectInfo = () => {
    DialogPresenter.present(onDismiss => (
      <ProjectInfoModal onDismiss={onDismiss} />
    ));
  };

  const renameProject = async () => {
    const text = await runTextFormModal({
      label: 'Project name',
      text: model.projectMetadata.name,
      confirmLabel: 'Rename',
    });
    if (text) {
      model.updateProjectName(text).catch(alertError);
    }
  };

  return (
    <>
      <Menu
        trigger={
          <IconButton
            icon={<BsThreeDotsVertical />}
            size={'small'}
            testId="project-overflow-menu"
          />
        }
      >
        <MenuItem
          leftIcon={<InfoCircleSmallIcon />}
          action={showProjectInfo}
          label={'View project info…'}
        />
        <MenuItem
          leftIcon={<EditSmallIcon />}
          action={() => void renameProject()}
          label={'Rename…'}
        />
        <MenuItem
          leftIcon={<ArchiveIcon />}
          action={() => void model.archive(!model.projectMetadata.archived)}
          label={model.archived ? 'Restore project' : 'Archive project'}
        />
        {auth.isSuperAdmin && model.task !== 'freeform' ? (
          <>
            <MenuItem
              leftIcon={<MergeIcon />}
              action={() => void handleMergeImportBackToUnit()}
              label={`Send back to script editor with merge (dev-only)`}
            />
            <MenuItem
              leftIcon={<MergeIcon />}
              action={() => void handleOverwriteImportBackToUnit()}
              label={`Send back to script editor with overwrite (dev-only)`}
            />
          </>
        ) : null}
        {
          model.showExploratoryUI ? (
            <>
              {/* <MenuItem
                action={() => void model.toggleTwoColumnMerge()}
                leftIcon={<LuColumns />}
                label={`${
                  model.projectMetadata.twoColumnMergeEnabled
                    ? 'Disable'
                    : 'Enable'
                } two column merge view`}
              /> */}
              <MenuItem
                leftIcon={<BsToggle2On />}
                action={() => void model.toggleSidebarMode()}
                label="Toggle sidebar mode"
              />
            </>
          ) : null
          // <MenuItem
          //   action={() => model.enableExperimentalUI()}
          //   leftIcon={<BsToggle2On />}
          //   label="Enable experimental UI"
          // />
        }
      </Menu>
    </>
  );
});
