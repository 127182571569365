import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Page } from '../shared/page';
import { useUnit } from './use-unit';
import { listAllUnitProjectMetadatas } from '@masala-lib/llm/project/llm-project-funcs';
import { createLogger } from '@app/logger';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';
import { createSamosaModel } from 'samosa/models/samosa-model';
import { ProjectTask } from '@masala-lib/llm/project/llm-project-types';
import { Unit } from '@masala-lib/catalog/models/unit';
import { observer } from 'mobx-react';
import { Table } from '../shared/table';
import { Shelf } from '../shared/shelf';
import { alertError } from 'ui/misc-utils';
import {
  ProjectMeta,
  enhanceProjectMetadata,
} from '@masala-lib/llm/project/project-meta';
import { Auth } from '@masala-lib/editorial/db/auth';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import styled from 'styled-components';

const log = createLogger('unit-assist-screen');

interface LoaderState {
  list?: ProjectMeta[];
  error?: Error;
}

const loadState = async ({
  unit,
  action,
}: {
  unit: Unit;
  action: string;
}): Promise<LoaderState> => {
  try {
    if (!unit) {
      // throw Error('unit param required');
      // seems to happen sometimes during page reload
      return {};
    }
    const unitId = unit.id;
    const rawList = await listAllUnitProjectMetadatas(unitId);
    const list = rawList.map(metadata => enhanceProjectMetadata(metadata));
    log.debug('loadState complete');
    return { list };
  } catch (e) {
    log.error(`loadState error: ${e}`);
    const error = e instanceof Error ? e : Error(String(e));
    return { error };
  }
};

const ProjectsSectionHeader = styled.div`
  font-size: 1.2em;
  border-bottom: 1px solid var(--color-gray50);
  margin-top: 1.2em;
  margin-left: 0.3em;
`;

export const UnitAssistScreen = observer(() => {
  const unit = useUnit();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = Auth.getInstance();

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { list, error } = loaderState;
  const busy = useSwitch2(false);

  const { action } = getQueryParamsFromLocation(location);

  React.useEffect(() => {
    loadState({ unit, action }).then(state => setLoaderState(state));
  }, [unit, action]);

  if (busy.value) {
    return <>creating new project ...</>;
  }

  if (!unit) {
    return null;
  }

  if (error) {
    return <>error: {String(error)}</>;
  }

  if (!list) {
    return <>...</>;
  }

  const openProjects = list.filter(
    meta => !meta.archived && meta.state === 'open'
  );

  const completedProjects = list.filter(
    meta => !meta.archived && meta.state !== 'open'
  );

  const archivedProjects = list.filter(meta => meta.archived);

  const goBack = () => {
    navigate(unit.navPath);
  };

  const newProject = async (
    task: ProjectTask,
    { preset }: { preset?: string }
  ) => {
    busy.on();
    // const name = `${unit?.name} - ${task} assist`;
    const name = `${auth.appUser.alias} - ${friendlyTaskName(task, preset)}`;
    try {
      const model = await createSamosaModel({
        unitId: unit.id,
        unitName: unit.name,
        task,
        user: auth.appUser.alias,
        name,
        preset,
      });
      navigate(`/samosa/${model.projectId}`);
    } catch (e) {
      alertError(e);
    }
  };

  const friendlyTaskName = (task: string, preset: string) => {
    if (task === 'translation') {
      return `${preset} ${task}`;
    } else if (task === 'structural') {
      return 'structural generation';
    } else {
      return task;
    }
  };

  return (
    <Page
      title={`${unit.name} - Machine Assisted Editorial projects`}
      onClose={goBack}
    >
      <div>
        New Project:{' '}
        <button
          onClick={() => newProject('translation', { preset: 'transcript' })}
        >
          transcript translation
        </button>{' '}
        &nbsp;{' '}
        <button
          onClick={() => newProject('structural', { preset: 'exploratory' })}
        >
          structural generation
        </button>
        &nbsp;{' '}
        <button
          onClick={() => newProject('translation', { preset: 'structural' })}
        >
          structural translation
        </button>{' '}
        &nbsp;{' '}
        <button onClick={() => newProject('vocab', { preset: 'transcript' })}>
          vocab
        </button>{' '}
        &nbsp;{' '}
        <button
          onClick={() => newProject('freeform', { preset: 'exploratory' })}
        >
          free form
        </button>{' '}
        {/* {auth.isSuperAdmin ? (
          <>
            &nbsp;{' '}
            <button
              onClick={() =>
                newProject('translation', { preset: 'exploratory' })
              }
            >
              exploratory translation
            </button>{' '}
          </>
        ) : null} */}
        <br />
        <br />
      </div>
      <ProjectsSectionHeader>Open Projects</ProjectsSectionHeader>
      <ProjectTable list={openProjects} />
      <ProjectsSectionHeader>Completed Projects</ProjectsSectionHeader>
      <ProjectTable list={completedProjects} />
      <ProjectsSectionHeader>Archived Projects</ProjectsSectionHeader>
      <ProjectTable list={archivedProjects} />
    </Page>
  );
});

const ProjectTable = observer(({ list }: { list: ProjectMeta[] }) => {
  return (
    <Table
      data={list}
      cells={[
        {
          header: 'Project',
          render: meta => <NavLink to={meta.navPath}>{meta.name}</NavLink>,
        },
        {
          header: 'Task',
          render: meta => meta.task,
        },
        {
          header: 'State',
          render: meta => meta.state,
        },
        {
          header: 'Started',
          render: meta => meta.agePretty,
        },
        {
          header: 'Actions',
          render: meta => <ProjectActions meta={meta} />,
        },
      ]}
    />
  );
});

const ProjectActions = observer(({ meta }: { meta: ProjectMeta }) => {
  const toggleArchived = async () => {
    await meta.toggleArchived();
    // window.location.reload();
  };

  return (
    <Shelf>
      <button
        onClick={toggleArchived}
        className={meta.archived ? '' : `danger-action`}
      >
        {meta.archived ? 'Unarchive' : 'Archive'}
      </button>
    </Shelf>
  );
});
