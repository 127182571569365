/* eslint-disable no-unused-vars */
// general purpose types depended upon by 'utils' shared code module

export interface StringToString {
  [index: string]: string;
}

export interface StringToNumber {
  [index: string]: number;
}

// client-side representation of a translated string
// when there's an implicit l1/l2 pair
export interface L12String {
  l1: string;
  l2: string;
}

// needs a little more thought
// torn between using 'l2' or the actual l2 locale code for the l2 values
// the magic 'l2' prop allows easier manipulation without always having access to the implicit l2 context
export type LString = {
  [index in LocaleCode | 'l2']: string;
};

// export enum LocaleCode { // 'Locale' or 'LocaleCode'?
//   en = 'en', // 'en-US': 'english',
//   es = 'es', // 'es-US': 'spanish',
//   ja = 'ja',
//   de = 'de', // 'de-DE': 'german',
//   pt = 'pt', // 'pt-BR': 'portuguese',
//   da = 'da', // 'da-DK': 'danish',
// }

export type LocaleCode = 'en' | 'es' | 'pt' | 'ja' | 'de' | 'da';
export const localeCodes = ['en', 'es', 'pt', ' ja', 'de', 'da'];

// export enum L12Code {
//   l1 = 'l1',
//   l2 = 'l2',
// }

export type L12Code = 'l1' | 'l2';
// export const l12Codes = ['l1', 'l2'];

// catalog slug per l1 for given l2
export type CatalogLookup = { [l1 in LocaleCode]: string } & {
  defaultL1: LocaleCode;
};

// catalog lookups per l2
export type CatalogsMap = {
  [l2 in LocaleCode]: CatalogLookup;
};

export type LocaleCodeFlags = {
  [index in LocaleCode]: boolean;
};
