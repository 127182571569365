import * as React from 'react';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ProjectTask } from '@masala-lib/llm/project/llm-project-types';
import { Button } from '@naan/primitives/button';
import { MergeIcon } from '../components/icons';

const TranslationMergeStatus = observer(() => {
  // const model = useSamosaModel();

  // return (
  //   <>
  //     {' '}
  //     (m:{model.outputIncompleteReferenceCount} c:{model.mergeConflictCount}){' '}
  //   </>
  // );
  return <></>; // remove any display on main screen until ready to style
});

const StructuralMergeStatus = () => <></>;

const mergeStatusMap: {
  [K in ProjectTask]: () => JSX.Element;
} = {
  translation: TranslationMergeStatus,
  vocab: TranslationMergeStatus, // TODO
  structural: StructuralMergeStatus,
  freeform: () => <></>,
};

export const MergeScriptItem = observer(() => {
  const model = useSamosaModel();

  // TODO can this be factored into something uniform accross tasks?
  const MergeStatus = mergeStatusMap[model.task];

  return (
    <>
      <Button
        leftIcon={<MergeIcon />}
        presentation={'grayLight'}
        size="small"
        radius={'squared'}
        onClick={() => model.touchupMergeScript()}
        label={'Merge…'}
        rightIcon={<MergeStatus />}
        fullWidth
      />
    </>
  );
});
