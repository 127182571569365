import { isEmpty, over } from 'lodash';
import { CreateChatCompletionResponse } from 'openai';
import { db } from '@platform/firebase-init';
import { QuerySnapshot, DocumentChange } from '@platform/firebase-types';
import { createLogger } from '@app/logger';
import {
  Range,
  Ranges,
  NumberSet,
  ParsedResponse,
  ProjectData,
  OverridesData,
  ProjectMetadata,
  ProjectDoc,
  AlertSuppressions,
  LLMMessage,
  Exchange,
  ProjectModel,
  ElementOverrideLookup,
  SuppressionsData,
  LintAlert,
  LLMOptions,
  Step,
  StepListLookup,
  CreateProjectParams,
  LlmModelChoice,
  ReferenceScript,
  ProjectTask,
  Notes,
  FlagsData,
  NotesData,
  Signoffs,
  SignoffsData,
  ScriptOptions,
  ResponseParserLookup,
  MASALA_FLAG,
  SAMOSA_FLAG,
  SUPPRESSED,
  ARCHIVED,
  ExchangeFlags,
  ElementFlags,
  AddedElementsData,
  Edit,
} from './llm-project-types';
import {
  ElementOrigin,
  ScriptElement,
  ScriptElementKind,
  SlotKey,
} from '../llm-types';
import {
  agePrettyTimestamp,
  numberProjectionSort,
  randomString,
} from '../../utils';
import {
  epochSeconds,
  fetchReferenceScriptData,
  hashForElement,
  llmTranslationTaskParseResponseText,
  llmVocabTaskParseResponseText,
  parseVocabElement,
  sortScriptElements,
} from '../llm-funcs';
import { elide } from '@utils/string-utils';
import { normalizeSpecialChars } from '@masala-lib/misc/editorial-string-utils';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { Intervals, NO_INDEX } from '@tikka/intervals/intervals';
import { DbPaths } from '@masala-lib/editorial/db/db';
import { llmParseStructuralResponse } from './structural-parsers';
// import { alertError } from 'ui/misc-utils';

const log = createLogger('llm-project-funcs');

export const fsCollectionName = 'LlmProjects';

export const llmModelChoices: LlmModelChoice[] = [
  // { key: 'anthropic/claude-3-5-sonnet-20240620', icon: '' },
  { key: 'anthropic/claude-3.5-sonnet', icon: '' },
  { key: 'openai/gpt-4o', icon: '' },
  { key: 'openai/gpt-4o-mini', icon: '' },
  { key: 'openai/gpt-4-turbo', icon: '' },
  { key: 'openai/gpt-4-turbo-preview', icon: '' },
  { key: 'openai/chatgpt-4o-latest', icon: '' },
  // { key: 'openai/gpt-4', icon: '' },
  // { key: 'openai/gpt-4-32k', icon: '' },
  // { key: 'openai/gpt-3.5-turbo', icon: '' },
  // { key: 'anthropic/claude-2', icon: '' },
  // { key: 'anthropic/claude-instant-v1', icon: '' },
  { key: 'anthropic/claude-3-opus', icon: '' },
  // { key: 'anthropic/claude-3-sonnet', icon: '' },
  // { key: 'openai/gpt-4-1106-preview', icon: '' },
  { key: 'echo', icon: '' },
];

export const llmModelKeys = llmModelChoices.map(choice => choice.key);

export function defaultLlmOptions(): LLMOptions {
  return {
    // model: 'simulation',
    // model: 'openai/gpt-4-32k',
    // model: 'anthropic/claude-2',
    model: deploymentConfig.defaultLlmModelKey,
    temperature: 0.7,
    systemPrompt: 'You are a helpful assistant.',
  };
}

export function projectsCollection() {
  return db.collection(fsCollectionName);
}

export async function listAllUnitProjectMetadatas(
  unitId: string
): Promise<ProjectMetadata[]> {
  const query = projectsCollection()
    .where('unitId', '==', unitId)
    .where('kind', '==', 'PROJECT_METADATA');
  // .where('archived', '!=', true); // need to promote to an indexed prop
  const querySnapshot = (await query.get()) as QuerySnapshot<
    ProjectDoc<ProjectMetadata>
  >;
  const list = querySnapshot.docs.map(doc => doc.data().data);
  const sorted = sortOnTimestamp(list).reverse();
  return sorted;
}

export async function listUnitProjectMetadatas(
  unitId: string
): Promise<ProjectMetadata[]> {
  const all = await listAllUnitProjectMetadatas(unitId);
  const active = all.filter(data => !data.archived);
  return active;
}

export async function listAllProjectMetadatas(): Promise<ProjectMetadata[]> {
  const query = projectsCollection()
    .where('kind', '==', 'PROJECT_METADATA')
    // .where('data.archived', '!=', true); // need to promote to an indexed prop
    .orderBy('timestamp', 'desc');
  const querySnapshot = (await query.get()) as QuerySnapshot<
    ProjectDoc<ProjectMetadata>
  >;
  const list = querySnapshot.docs.map(doc => doc.data().data);
  const active = list.filter(data => !data.archived);
  const sorted = sortOnTimestamp(active).reverse();
  return sorted;
}

const presetMetadataParams: { [index: string]: Partial<ProjectMetadata> } = {
  transcript: { subtask: 'transcript' },
  vocab: { subtask: 'transcript' },
  structural: { subtask: 'structural' }, // translation
  exploratory: { subtask: 'exploratory' },
};

// spawn a new project for a given unitId
export async function createProjectMetadata(
  params: CreateProjectParams
): Promise<ProjectMetadata> {
  const { preset, ...otherParams } = params;
  const presetParams = presetMetadataParams[preset] || {};
  const projectId = `${params.unitId}-${randomString(8)}`;
  const metadataDoc: ProjectMetadata = {
    kind: 'PROJECT_METADATA',
    dbStructureVersion: 0.5,
    projectId,
    llmOptions: defaultLlmOptions(),
    state: 'open',
    threadCounter: 0,
    ...presetParams,
    ...otherParams,
  };
  const result = await saveProjectDoc<ProjectMetadata>(projectId, metadataDoc);
  return result;
}

export function allocateDocId(projectId: string) {
  return `${projectId}-${randomString(8)}`;
}

export function counterToLetterCode(counter: number): string {
  const repeat = Math.ceil(counter / 26);
  const base = ((counter - 1) % 26) + 1;
  const result = String.fromCharCode(64 + base).repeat(repeat);
  return result;
}
(window as any).counterToLetterCode = counterToLetterCode;

export async function saveProjectDoc<D extends ProjectData>(
  projectId: string,
  data: D
): Promise<D> {
  data.id ??= allocateDocId(projectId);
  data.projectId ??= projectId;
  data.timestamp ??= epochSeconds();
  const doc: ProjectDoc<D> = {
    id: data.id,
    projectId,
    kind: data.kind,
    timestamp: data.timestamp,
    unitId: (data as ProjectMetadata).unitId, // todo: clean this up
    data,
  };
  const docRef = projectsCollection().doc(data.id);
  await docRef.set(doc);
  return data;
}

export async function deleteProjectDoc(id: string): Promise<void> {
  const docRef = projectsCollection().doc(id);
  await docRef.delete();
}

export async function fetchAllProjectDatas(
  projectId: string
): Promise<ProjectData[]> {
  const query = projectsCollection().where('projectId', '==', projectId);
  const querySnapshot = (await query.get()) as QuerySnapshot<ProjectDoc>;
  const list = querySnapshot.docs.map(doc => doc.data().data);
  return list;
}

export function listenAllProjectDocs(
  projectId: string,
  callback: (querySnapshot: QuerySnapshot) => void
) {
  const q = projectsCollection().where('projectId', '==', projectId);
  const unsubscribe = q.onSnapshot(callback);
  return unsubscribe;
}

export function handleProjectDocChange(
  projectModel: ProjectModel,
  change: DocumentChange<ProjectDoc>
) {
  log.debug(
    `handleProjectDocChange - ${change.type} ${change.doc.id} ${
      change.doc.data()?.data?.kind
    }`
  );
  // change types are added, removed, modified
  const projectData = change.doc.data().data;
  if (change.type === 'added' || change.type === 'modified') {
    handleUpdatedProjectData(projectModel, projectData);
  } else {
    if (change.type === 'removed' && projectData.kind === 'EXCHANGE') {
      projectModel.stepMap.delete(projectData.id);
    } else {
      throw Error(`unexpected change type: ${change.type}`);
    }
  }
}

export function handleUpdatedProjectData(
  projectModel: ProjectModel,
  data: ProjectData
) {
  // @jason, not clear to me that this code is preferable here vs an interface on the projectModel
  // projectModel.applyUpdatedData(data);
  switch (data.kind) {
    case 'PROJECT_METADATA':
      projectModel.projectMetadata = data;
      break;
    case 'REFERENCE_SCRIPT':
      projectModel.referenceScript = data;
      // placed redundantly into stepMap to fascilitate thread calculations
      projectModel.stepMap.set(data.id, data);
      break;
    case 'EXCHANGE':
      projectModel.stepMap.set(data.id, data);
      break;
    case 'OVERRIDE_EDITS':
      projectModel.docIds.editsDocId = data.id;
      projectModel.editLookup = data.overrideLookup;
      break;
    case 'ADDED_ELEMENTS':
      projectModel.docIds.addedElementsDocId = data.id;
      projectModel.addedElementsLookup = data.addedElementsLookup;
      break;
    case 'EXCHANGE_FLAGS':
      projectModel.docIds.exchangeFlagsDocId = data.id;
      projectModel.exchangeFlags = data.flags;
      break;
    case 'ELEMENT_FLAGS':
      projectModel.docIds.elementFlagsDocId = data.id;
      projectModel.elementFlags = data.flags;
      break;
    case 'ALERT_SUPPRESSIONS':
      projectModel.docIds.suppressedAlertsDocId = data.id;
      projectModel.suppressedAlerts = data.suppressions;
      break;
    case 'NOTES':
      projectModel.docIds.notesDocId = data.id;
      projectModel.notes = data.notes;
      break;
    case 'SIGNOFFS':
      projectModel.docIds.signoffsDocId = data.id;
      projectModel.signoffs = data.signoffs;
      break;
    case 'IMPORT_SCRIPT':
      projectModel.importScript = data;
      break;
    default:
      break;
  }
}

// export const SHOW_ALL_EXCHANGES = 'SHOW_ALL_EXCHANGES';

// text assembly mirror to ProjectSummary component for handy display as Page title.
// probably not needed once layout work complete
export function projectSummary(metadata: ProjectMetadata): string {
  let result = `${metadata.name || metadata.projectId} - task: ${
    metadata.task
  }, started: ${agePrettyTimestamp(metadata.timestamp)} - state: ${
    metadata.state
  }`;
  if (metadata.completedTimestamp) {
    result += ` - ${agePrettyTimestamp(metadata.completedTimestamp)}`;
  }
  if (metadata.archived) {
    result += ' (ARCHIVED)';
  }
  return result;
}

// todo: flush out what we want to display in the left nav for exchanges
export function labelForExchange(exchange: Exchange) {
  // return `${localStringFromEpoch(exchange.timestamp)} - ${exchange.id}`;
  // return `${agePrettyTimestamp(exchange.timestamp)} - ${exchange.id}`;
  const { age, requestHead, range } = displayPropsForExchange(exchange);
  return `${requestHead}, ${age}, ${range}`;
}

export function displayPropsForExchange(exchange: Exchange) {
  return {
    age: agePrettyTimestamp(exchange.timestamp),
    requestHead: requestHead(exchange),
    range: rangeDisplaySummary(exchange),
    alertCount: 0, // todo
  };
}

export function exchangeLabel(exchange: Exchange) {
  const code = exchange.code;
  if (code) {
    if (exchange.label) {
      return `${code}: ${exchange.label}`;
    } else {
      return code;
    }
  } else {
    if (exchange.label) {
      return exchange.label;
    } else {
      return '';
    }
  }
}

export function requestHead(exchange: Exchange) {
  const code = exchange.code;
  const prefix = !!code ? `${code}: ` : '';
  const text = `${prefix}${exchange.label || exchange.request.text}`;
  return elide(text, 50);
}

export function refsOfResponse(parsedResponse: ParsedResponse) {
  return parsedResponse.elements.map(el => el.reference).filter(ref => ref);
}

export function rangeDisplaySummary(exchange: Exchange): string {
  const refs = refsOfResponse(exchange.parsedResponse);
  return `${Math.min(...refs)}-${Math.max(...refs)}`;
}

export function rangeOfNumbers(numbers: number[]): Range {
  return {
    begin: Math.min(...numbers),
    end: Math.max(...numbers),
  };
}

//
// jfe - to be digested
//

export function unionSet<T>(a: Set<T>, b: Set<T>): Set<T> {
  return new Set<T>([...a.values(), ...b.values()]);
}

export function minusSet<T>(a: Set<T>, b: Set<T>): Set<T> {
  const result = new Set<T>();
  for (const aVal of a.values()) {
    if (!b.has(aVal)) {
      result.add(aVal);
    }
  }
  return result;
}

export function numberSetFromRanges(ranges: Ranges): NumberSet {
  const result = new Set<number>();
  for (const r of ranges) {
    for (let n = r.begin; n <= r.end; n++) {
      result.add(n);
    }
  }
  return result;
}

export function rangesFromNumberSet(numberSet: NumberSet): Ranges {
  const result: Ranges = [];
  const values = [...numberSet.values()].sort((a, b) => a - b);
  const len = values.length;
  if (!len) {
    return result;
  }
  let index = 0;
  let rBegin = values[index];
  let rEnd = values[index];
  index++;
  while (index < len) {
    const val = values[index];
    if (val === rEnd + 1) {
      rEnd = val;
    } else {
      result.push({
        begin: rBegin,
        end: rEnd,
      });
      rBegin = val;
      rEnd = val;
    }
    index++;
  }
  result.push({
    begin: rBegin,
    end: rEnd,
  });
  return result;
}

export function rangeFromNumbers(numbers: number[]): Range {
  return {
    begin: Math.min(...numbers),
    end: Math.max(...numbers),
  };
}

export function filterScriptElementsByRange(
  elements: ScriptElement[],
  range: Range
): ScriptElement[] {
  if (!range) {
    return elements;
  }
  return elements.filter(
    el => el.reference >= range.begin && el.reference <= range.end
  );
}

export function sortOnTimestamp<T extends { timestamp?: number }>(
  list: T[]
): T[] {
  const result = [...list];
  numberProjectionSort(result, (o: T) => o.timestamp);
  return result;
}

export function scriptElementsFromParsedResponses(
  responses: ParsedResponse[]
): ScriptElement[] {
  const result: ScriptElement[] = [];
  for (const response of responses) {
    result.push(...response.elements);
  }
  return result;
}

export async function initUnitDataFromMasala(
  projectMetadata: ProjectMetadata,
  unitId: string
) {
  const paths = new DbPaths(db, unitId);
  const unitData = (await paths.unitMetadataDocRef.get()).data();
  const l1Code = unitData?.l1Code;
  const l2Code = unitData?.l2Code;
  const l0IsTranslation = l1Code === 'en';
  const unitName = unitData?.name;
  // todo: pull title from volume data since might not be populated on unit data
  const unitTitle =
    (l0IsTranslation ? unitData?.infoV5?.titleL1 : unitData?.infoV5?.titleL2) ||
    unitName;
  await mergeProjectData<ProjectMetadata>(projectMetadata, {
    l1Code,
    l2Code,
    l0IsTranslation,
    unitName,
    unitTitle,
  });
}

export async function initReferenceScriptFromMasala(
  projectId: string,
  unitId: string,
  params: {
    task: ProjectTask;
    subtask: string; // will likely want a wider range of metadata props
  }
) {
  const { task, subtask } = params;
  let [
    referenceElements,
    translationElements,
    sectionBoundaries,
    timesLookup,
    audioUrl,
  ] = await fetchReferenceScriptData(unitId, params);
  const references = referenceElements.map(el => el.reference);
  const referenceNumSet = new Set(references);
  // @jason what is the 'referenceRanges' about?
  const referenceRanges = rangesFromNumberSet(referenceNumSet);
  // const sectionBoundaries = boundariesFromChapters(referenceElements);
  // const sectionRanges = chapterRangesOfScriptElements(elements);
  const sectionRanges = boundariesToRanges(sectionBoundaries);
  const elementIdToTranslation: { [id: string]: ScriptElement } = {};
  for (const el of translationElements) {
    // elementIdToTranslation[el.reference] = el; // todo: either change the prop name or index by element id, not ref number
    const id = el.id.replace('TRANSLATION:', '').slice(0, -3); // strip prefix and locale suffix
    elementIdToTranslation[id] = el;
  }
  if (task === 'vocab' || task === 'freeform') {
    referenceElements.push(...translationElements);
    referenceElements = sortScriptElements(referenceElements);
  }

  const referenceScript: ReferenceScript = {
    kind: 'REFERENCE_SCRIPT',
    projectId,
    priorId: null,
    elements: referenceElements,
    referenceRanges, // ?
    sectionBoundaries,
    sectionRanges,
    elementIdToTranslation,
    timesLookup,
    audioUrl,
  };
  await saveProjectDoc<ReferenceScript>(projectId, referenceScript);
  // this.setReferenceScript(savedData); // won't be needed once firebase listening flow wired up
}

export async function initExchangeFlags(projectId: string) {
  const data: ExchangeFlags = {
    projectId,
    kind: 'EXCHANGE_FLAGS',
    id: null,
    flags: {},
  };
  await saveProjectDoc(projectId, data);
}

export async function initElementFlags(projectId: string) {
  const data: ElementFlags = {
    projectId,
    kind: 'ELEMENT_FLAGS',
    id: null,
    flags: {},
  };
  await saveProjectDoc(projectId, data);
}

export async function initElementOverridesDoc(projectId: string) {
  const overridesData: OverridesData = {
    kind: 'OVERRIDE_EDITS',
    projectId,
    id: null,
    overrideLookup: {},
  };
  await saveProjectDoc(projectId, overridesData);
}

export async function initAddedElementsDoc(projectId: string) {
  const addedElementsData: AddedElementsData = {
    kind: 'ADDED_ELEMENTS',
    projectId,
    id: null,
    addedElementsLookup: {},
  };
  await saveProjectDoc(projectId, addedElementsData);
}

export async function initAlertSuppressionsDoc(projectId: string) {
  const suppressions: AlertSuppressions = {
    kind: 'ALERT_SUPPRESSIONS',
    projectId,
    id: null,
    suppressions: {},
  };
  await saveProjectDoc(projectId, suppressions);
}

export async function initNotesDoc(projectId: string) {
  const notes: Notes = {
    kind: 'NOTES',
    projectId,
    id: null,
    notes: {},
  };
  await saveProjectDoc(projectId, notes);
}

export async function initSignoffsDoc(projectId: string) {
  const signoffs: Signoffs = {
    kind: 'SIGNOFFS',
    projectId,
    id: null,
    signoffs: {},
  };
  await saveProjectDoc(projectId, signoffs);
}

// // todo: refactor/consolidate with createProject
// // TODO pass unitId??
// export async function initProjectMetadataDoc(projectId: string) {
//   const metadata: ProjectMetadata = {
//     kind: 'PROJECT_METADATA',
//     projectId,
//     state: 'open',
//     // id: null,
//     unitId: null,
//     // timestamp: epochSeconds(),
//   };
//   await saveProjectDoc(projectId, metadata);
// }

// export async function initProjectDocs(projectId: string) {
//   await initProjectMetadataDoc(projectId);
//   await initEditsDoc(projectId);
//   await initSuppresionsDoc(projectId);
// }

// export async function saveExchangeDoc(projectId: string, exchange: Exchange) {
//   await saveProjectDoc(projectId, exchange);
// }

// export async function saveReferenceScriptDoc(
//   projectId: string,
//   referenceScript: ReferenceScript
// ) {
//   await saveProjectDoc(projectId, referenceScript);
// }

export function llmMessagesFromExchanges(exchanges: Exchange[]) {
  const result: LLMMessage[] = [];
  for (const exchange of exchanges) {
    result.push({ role: 'user', content: exchange.request.text });
    result.push({ role: 'assistant', content: exchange.rawResponse.text });
  }
  return result;
}

export function createIndexOnPriorId(steps: Step[]): StepListLookup {
  const result: StepListLookup = {} as any;

  for (const step of steps) {
    const priorId = step.priorId;
    result[step.id] ??= [];
    result[priorId] ??= [];
    const list = result[priorId];
    list.push(step);
  }
  return result;
}

export function computeForwardThread(
  step: Step,
  stepsWithPriorId: StepListLookup
): Step[] {
  const result: Step[] = [];
  let workingStep = step;
  while (true) {
    const forwardSteps = stepsWithPriorId[workingStep.id];
    if (forwardSteps.length === 1) {
      const nextStep = forwardSteps[0];
      result.push(nextStep);
      workingStep = nextStep;
    } else {
      break;
    }
  }
  return result;
}

export function isTerminalStep(step: Step, stepsWithPriorId: StepListLookup) {
  return stepsWithPriorId[step.id].length === 0;
}

export async function setSuppressExchange(
  exchangeFlagsDocId: string,
  exchangeFlags: FlagsData,
  exchangeId: string,
  suppress: boolean
) {
  const newValue = setSuppressedFlag(exchangeFlags[exchangeId], suppress);

  const data: Partial<ExchangeFlags> = {
    flags: {
      [exchangeId]: newValue,
    },
  };

  const update = { data };
  const docRef = db.collection(fsCollectionName).doc(exchangeFlagsDocId);
  await docRef.set(update, { merge: true });
}

// @jason, do exchange and element suppressions really need separate docs and parallel implementions?
// export async function setSuppressElement(
//   docId: string,
//   exchangeElementKey: string,
//   suppress: boolean
// ) {
//   const data: Partial<ExchangeSuppressions> = {
//     suppressions: {
//       [exchangeElementKey]: suppress,
//     },
//   };
//   const update = { data };
//   const docRef = db.collection(fsCollectionName).doc(docId);
//   await docRef.set(update, { merge: true });
// }

// // support for hack UI which can't yet unsuppress individual elements
// export async function resetAllElementSuppressions(docId: string) {
//   const data: Partial<ElementSuppressions> = {
//     suppressions: {},
//   };
//   const update = { data };
//   const docRef = db.collection(fsCollectionName).doc(docId);
//   await docRef.set(update, { merge: false });
// }

// @jason, i was originally assuming the key should be scoped by exchange,
// but we don't seem to have an easy way to map from element to exchange right now
export function elementSuppressionKey(
  exchange: Exchange,
  element: ScriptElement
) {
  // return `${exchange.id},${element.id}`;
  return element.id;
}

export async function mergeElementEdits(
  docId: string,
  edits: ElementOverrideLookup
) {
  if (isEmpty(edits)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<OverridesData> = {
    overrideLookup: edits,
  };
  await docRef.set({ data }, { merge: true });
}

export async function mergeAddedElements(
  docId: string,
  addedElements: ElementOverrideLookup
) {
  if (isEmpty(addedElements)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<AddedElementsData> = {
    addedElementsLookup: addedElements,
  };
  await docRef.set({ data }, { merge: true });
}

export async function mergeAlertSuppressions(
  docId: string,
  suppressions: SuppressionsData
) {
  if (isEmpty(suppressions)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<AlertSuppressions> = {
    suppressions,
  };
  await docRef.set({ data }, { merge: true });
}

export async function mergeExchangeFlags(
  docId: string,
  flags: FlagsData
): Promise<void> {
  if (isEmpty(flags)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<ExchangeFlags> = {
    flags,
  };
  await docRef.set({ data }, { merge: true });
}

export async function mergeElementFlags(
  docId: string,
  flags: FlagsData
): Promise<void> {
  if (isEmpty(flags)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<ElementFlags> = {
    flags,
  };
  await docRef.set({ data }, { merge: true });
}

export async function mergeNotes(docId: string, notes: NotesData) {
  if (isEmpty(notes)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<Notes> = {
    notes,
  };
  await docRef.set({ data }, { merge: true });
}

export async function mergeSignoffs(docId: string, signoffs: SignoffsData) {
  if (isEmpty(signoffs)) {
    return;
  }
  const docRef = db.collection(fsCollectionName).doc(docId);
  const data: Partial<Signoffs> = {
    signoffs,
  };
  await docRef.set({ data }, { merge: true });
}

// // chapter title elements refs, plus ref of final element+1
// // used to generate default ranges
// export function boundariesFromChapters(elements: ScriptElement[]): number[] {
//   const chapters = elements.filter(el => el.kind === 'CHAPTER');
//   const result = chapters.map(ch => ch.reference);
//   result.push(elements.at(-1).reference + 1);
//   return result;
// }

// generate section reference ranges from section head element refs
// (assumes refs are 1 based and sequential)
export function boundariesToRanges(bondaries: number[]): Range[] {
  const result: Range[] = [];
  for (let i = 0; i < bondaries.length - 1; i++) {
    result.push({
      begin: bondaries[i],
      end: bondaries[i + 1] - 1,
    });
  }
  return result;
}

// chatgpt generatead
export function insertOrRemoveOnSortedArray(
  sortedArray: number[],
  n: number
): number[] {
  // Find the index where the number should be inserted
  const index = sortedArray.findIndex(val => val >= n);

  // If the array doesn't have the number, insert it
  if (index === -1 || sortedArray[index] !== n) {
    sortedArray.splice(index === -1 ? sortedArray.length : index, 0, n);
  } else {
    // Otherwise, remove the number from the array
    sortedArray.splice(index, 1);
  }

  return sortedArray;
}

// export function chapterRangesOfScriptElements(
//   elements: ScriptElement[]
// ): Range[] {
//   const chapters = elements.filter(el => el.kind === 'CHAPTER');
//   const result: Range[] = [];
//   for (const [index, chapter] of chapters.entries()) {
//     if (chapters.length > index + 1) {
//       result.push({
//         begin: chapter.reference,
//         end: chapters[index + 1].reference - 1,
//       });
//     } else {
//       result.push({
//         begin: chapter.reference,
//         end: elements.at(-1).reference,
//       });
//     }
//   }
//   return result;
// }

export function filterToActiveAlerts(
  alerts: LintAlert[],
  alertSuppressions: SuppressionsData,
  elementFlags: FlagsData
) {
  return alerts.filter(alert => {
    return (
      !alertSuppressions[alert.key] &&
      !getSuppressedFlag(elementFlags[alert.elementId])
    );
  });
}

export function setLintAlertKey(alert: LintAlert) {
  alert.key = `${alert.elementId}:${alert.kind}`;
}

export function computeSlotConflicts(elements: ScriptElement[]): SlotKey[] {
  // assuming input is sorted such the elements with the same claimed slot are adjacent
  const result: SlotKey[] = [];
  let conflicting: ScriptElement[] = [];
  let i = 0;
  let j = 0;
  const len = elements.length;
  while (i + 1 < len) {
    j = 0;
    while (j + i + 1 < len) {
      const current = elements[i + j];
      const next = elements[i + j + 1];
      if (current.claimedSlot !== next.claimedSlot) {
        break;
      }
      if (j === 0) {
        conflicting.push(elements[i]);
      }
      conflicting.push(elements[i + j + 1]);
      j++;
    }
    if (conflicting.length > 0) {
      result.push(conflicting[0].claimedSlot);
      conflicting = [];
    }
    i++;
  }
  return result;
}

export function translationTaskComputeReferenceConflicts(
  elements: ScriptElement[]
): number[] {
  // assuming input is translations sorted by reference for this task
  const result: number[] = [];
  let conflicting: ScriptElement[] = [];
  let i = 0;
  let j = 0;
  const len = elements.length;
  while (i + 1 < len) {
    j = 0;
    while (j + i + 1 < len) {
      const current = elements[i + j];
      const next = elements[i + j + 1];
      if (current.reference !== next.reference) {
        break;
      }
      if (j === 0) {
        conflicting.push(elements[i]);
      }
      conflicting.push(elements[i + j + 1]);
      j++;
    }
    if (conflicting.length > 0) {
      result.push(conflicting[0].reference);
      conflicting = [];
    }
    i++;
  }
  return result;
}

export function computeReverseThread(
  step: Step,
  stepLookup: Map<string, Step>
): Step[] {
  if (!step) {
    return [];
  }
  const result = [step];
  let priorId = step.priorId;
  while (priorId) {
    const prior = stepLookup.get(priorId);
    if (prior) {
      result.unshift(prior);
    } else {
      log.warn(`unexpectedly missing prior step for id: ${priorId}`);
    }
    priorId = prior?.priorId;
  }
  return result;
}

const openRouterLlmHeaders = {
  'HTTP-Referer': 'http://typingmind.com',
  'X-Title': 'typingmind',
  'Content-Type': 'application/json',

  // jiveworld.it@gmail.com account created key
  Authorization:
    'Bearer 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',
  // original key created under jrw's account
  // 'Bearer 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',
};

export const openAiLlmHeaders = {
  'Content-Type': 'application/json',

  Authorization:
    // it@jiveworld.com account
    // 'Bearer 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',
    // jiveworld.it@gmail account
    // 'Bearer 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',
    'Bearer sk-proj-mIyMHrxAqLZNXXG0zYI9T3BlbkFJYpseaLzdhBoeuQmWYG5w',
};

export const openAiLlmEndpoint = 'https://api.openai.com/v1/chat/completions';
const openRouterLlmEndpoint = 'https://openrouter.ai/api/v1/chat/completions';

// export const openAiDirectModelNames = [
//   'gpt-4',
//   'gpt-4o',
//   'gpt-4-turbo',
//   'gpt-4-1106-preview',
// ];

export function isOpenAi(modelKey: string) {
  const [provider, model] = modelKey.split('/');
  return provider === 'openai' && model !== 'gpt-4-32k'; // route the 32k model requests to open router
}

export function llmEndpoint(modelKey: string) {
  return isOpenAi(modelKey) ? openAiLlmEndpoint : openRouterLlmEndpoint;
}

export function llmHeaders(modelKey: string) {
  return isOpenAi(modelKey) ? openAiLlmHeaders : openRouterLlmHeaders;
}

export function payloadModel(modelKey: string) {
  if (isOpenAi(modelKey)) {
    return modelKey.split('/')[1]; // just send along the tail for the open ai endpoint
  } else {
    return modelKey;
  }
}

export async function invokeLlm(
  priorMessages: LLMMessage[],
  messageText: string,
  options: LLMOptions,
  streamCallback?: (text: string) => void,
  shouldCancelCallback?: () => boolean
): Promise<string> {
  const messages = [
    { role: 'system', content: options.systemPrompt },
    ...priorMessages,
    { role: 'user', content: messageText },
  ];

  const model = options.model;
  const data = {
    model: payloadModel(model),
    stream: !!streamCallback,
    messages,
  };

  // todo: revisit this
  const controller = new AbortController();
  const signal = controller.signal;

  if (options.model === 'echo') {
    const response = await simulatedFetch({
      messageText,
      streamCallback,
      shouldCancelCallback,
    });
    return response;
  }

  const response = await fetch(llmEndpoint(model), {
    method: 'POST',
    headers: llmHeaders(model),
    body: JSON.stringify(data),
    signal,
  });

  let jsonResponse: any;
  const decoder = new TextDecoder();
  let cumulativeResponse = '';
  let latestText: string = '';
  if (streamCallback) {
    //
    // streaming logic derived from: https://www.reddit.com/r/ChatGPT/comments/11m3jdw/chatgpt_api_streaming/
    //
    const reader = response.body.getReader();

    while (true) {
      // todo: think more about error handling
      try {
        const { done, value } = await reader.read();
        const chunk = decoder.decode(value, { stream: true });
        cumulativeResponse += chunk;
        if (done) {
          // not sure if this flow is relevant, probably only if response abrubtly interrupted
          return latestText;
        }
      } catch (error) {
        log.error(`invokeLlm read chunk error: ${error}`);
        // alertError(error);
        // todo: report error to user within console build
        // todo: mark at interrupted
        return latestText;
      }
      if (shouldCancelCallback()) {
        log.info('shouldCancel signal received');
        controller.abort();
        return latestText;
      }

      // split response into individual data objects
      const lines = cumulativeResponse.split('\n').filter(Boolean);
      const hasError = lines.some(
        line => !line.startsWith('data: ') && line.includes(`"error":`)
      );
      if (hasError) {
        log.error(cumulativeResponse);
        const jsonData = JSON.parse(cumulativeResponse);
        const message =
          jsonData.error.message || 'unexpected llm error response format';
        throw Error(message);
      }

      let doneReported = false;
      const contents = lines.map(line => {
        if (!line.startsWith('data: ')) {
          console.log(`ignoring line: ${line}`);
          return '';
        }
        const lineData = line.replace(/^data: /, '');
        if (lineData === '[DONE]') {
          doneReported = true;
          return '';
        }
        const jsonData = JSON.parse(lineData);
        if (jsonData.choices) {
          const content = jsonData.choices[0].delta.content as string;
          return content;
        } else {
          return '';
        }
      });
      latestText = contents.join('');

      streamCallback(latestText);

      if (doneReported) {
        return latestText;
      }
    }
  } else {
    log.debug('before await response.json()');
    // jsonResponse = await response.json();
    const textResponse = await response.text();
    try {
      jsonResponse = JSON.parse(textResponse);
    } catch (e) {
      log.error(`error parsing llm response: ${e}`);
      const plainText = stripAngleBracketedTags(textResponse);
      return plainText;
    }

    const result: CreateChatCompletionResponse = jsonResponse;
    // todo: error handling for non-streamed flow
    return result.choices[0]?.message?.content || JSON.stringify(jsonResponse);
  }
}

export function stripAngleBracketedTags(text: string) {
  return text.replace(/<[^>]*>/g, '');
}

// export function translationTaskParsedResponse(
export function standardTranslationParser(
  responseText: string,
  timestamp?: number
): ParsedResponse {
  log.info('standardTranslationParser');
  const elements = llmTranslationTaskParseResponseText(responseText, timestamp);
  const alerts: LintAlert[] = [];

  return {
    elements,
    alerts,
  };
}

// export function structureTaskParsedResponse(
export function standardStructuralParser(
  text: string,
  timestamp?: number
): ParsedResponse {
  log.info('standardStructuralParser');
  const alerts: LintAlert[] = [];
  // const elements = llmParseStructuralResponse(text, alerts, timestamp);
  const elements = llmParseStructuralResponse(text, alerts, timestamp);

  return {
    elements,
    alerts,
  };
}

export function standardVocabParser(
  responseText: string,
  timestamp?: number
): ParsedResponse {
  log.info('standardTranslationParser');
  const elements = llmVocabTaskParseResponseText(responseText, timestamp);
  const alerts: LintAlert[] = [];
  for (const el of elements) {
    const parsed = parseVocabElement(el);
    if (!parsed) {
      alerts.push({
        kind: 'PARSE_DETAIL',
        elementId: el.id,
        message: `unable to parse vocab element`,
        key: `PARSE_DETAIL:${el.hash}`,
        reference: el.reference,
        level: 'WARNING',
      });
    }
  }

  return {
    elements,
    alerts,
  };
}

// @jason, should we care about making sure our parser keys are distinct between task types?
export const translationResponseParsers: ResponseParserLookup = {
  // 'standard-translation': translationTaskParsedResponse,
  standard: standardTranslationParser,
};

export const structuralResponseParsers: ResponseParserLookup = {
  // 'standard-structural': structureTaskParsedResponse,
  standard: standardStructuralParser,
};

export const vocabResponseParsers: ResponseParserLookup = {
  // 'standard-translation': translationTaskParsedResponse,
  // TODO
  standard: standardVocabParser,
};

export function roughCountLines(text: string) {
  return (text.match(/\n/g) || '').length + 1;
}

export function shouldTruncate(text: string, maxLines: number) {
  return roughCountLines(text) > maxLines;
}

async function simulatedFetch({
  messageText,
  streamCallback,
  shouldCancelCallback,
}: {
  messageText: string;
  streamCallback: (text: string) => void;
  shouldCancelCallback: () => boolean;
}): Promise<string> {
  // chatgpt generated
  const CHUNK_SIZE = 10;
  const DELAY_MS = 200;

  let latestText: string = '';

  for (let i = 0; i < messageText.length; i += CHUNK_SIZE) {
    // Check if we should cancel the operation before sending each chunk
    if (shouldCancelCallback()) {
      log.info('shouldCancel signal received');
      return latestText;
    }

    // Get the next chunk and pass it to the stream callback
    const chunk = messageText.substring(i, i + CHUNK_SIZE);
    latestText += chunk;
    streamCallback(latestText);

    // If there's more data to send, delay for the specified time
    if (i + CHUNK_SIZE < messageText.length) {
      await new Promise(resolve => setTimeout(resolve, DELAY_MS));
    }
  }
  return latestText;
}

export function countsPerInterval(values: number[], intervals: Intervals) {
  try {
    const result: number[] = new Array(intervals.length).fill(0);

    for (const value of values) {
      const idx = intervals.containing(value);
      if (idx === NO_INDEX) {
        throw Error('somehow value not in intervals');
      }
      result[idx]++;
    }
    return result;
  } catch (e) {
    // @jason, this was blowing up for structural responses
    const error = e instanceof Error ? e : Error(String(e));
    log.error('mergeConflictsCountsBySection error:', error);
    log.info(error.stack);
    return [];
  }
}

export function exchangesByIntervals(
  exchanges: Exchange[],
  intervals: Intervals
): [Step[][], Step[]] {
  let result: Step[][] = new Array(intervals.length).fill(null);
  let noFit: Step[] = [];
  result = result.map(r => []);
  for (const exchange of exchanges) {
    const refs = refsOfResponse(exchange.parsedResponse);
    const begin = Math.min(...refs);
    // TODO put containsInterval method on Intervals?
    const idx = intervals.lastStartsBeforeOrAt(begin);
    if (idx === NO_INDEX) {
      noFit.push(exchange);
    } else {
      result[idx].push(exchange);
    }
  }
  return [result, noFit];
}

export function buildUpdateProjectData<D extends ProjectData>(
  data: D,
  update: Partial<D> = {}
): Partial<D> {
  const merged = {
    id: data.id,
    ...update,
  } as Partial<D>;
  return merged;
}

export async function applyUpdateProjectData<D extends Partial<ProjectData>>(
  data: D
): Promise<void> {
  const docRef = projectsCollection().doc(data.id);
  await docRef.set({ data }, { merge: true });
}

export async function mergeProjectData<D extends ProjectData>(
  projectData: D,
  update: Partial<D> = {}
): Promise<void> {
  const merged = buildUpdateProjectData(projectData, update);
  await applyUpdateProjectData(merged);
}

export async function toggleArchived(metadata: ProjectMetadata) {
  metadata.archived = !metadata.archived;
  await mergeProjectData(metadata, {
    archived: metadata.archived,
  });
}

export function buildLookupByReference(elements: ScriptElement[]) {
  const result: Map<number, ScriptElement> = new Map();
  for (const element of elements) {
    if (element.reference) {
      result.set(element.reference, element);
    }
  }
  return result;
}

export function getElementKindsFromScriptOptions(options: ScriptOptions) {
  const result: ScriptElementKind[] = [];
  if (options.chapters) {
    result.push('CHAPTER');
  }
  if (options.passages) {
    result.push('PASSAGE');
  }
  if (options.speakers) {
    result.push('SPEAKER_LABEL');
  }
  if (options.sentences) {
    result.push('SENTENCE');
  }
  if (options.translations) {
    result.push('TRANSLATION');
  }
  return result;
}

export function getFlag(flags: number, flag: number): boolean {
  flags = flags || 0;
  return (flags & flag) === flag;
}

export function setFlag(flags: number, flag: number, value: boolean): number {
  flags = flags || 0;
  return value ? flags | flag : flags & ~flag;
}

export function toggleFlag(flags: number, flag: number): number {
  flags = flags || 0;
  return flags ^ flag;
}

export function getMasalaFlag(flags: number): boolean {
  return getFlag(flags, MASALA_FLAG);
}

export function setMasalaFlag(flags: number, value: boolean): number {
  return setFlag(flags, MASALA_FLAG, value);
}

export function toggleMasalaFlag(flags: number): number {
  return toggleFlag(flags, MASALA_FLAG);
}

export function getSamosaFlag(flags: number): boolean {
  return getFlag(flags, SAMOSA_FLAG);
}

export function setSamosaFlag(flags: number, value: boolean): number {
  return setFlag(flags, SAMOSA_FLAG, value);
}

export function toggleSamosaFlag(flags: number): number {
  return toggleFlag(flags, SAMOSA_FLAG);
}

export function getSuppressedFlag(flags: number): boolean {
  return getFlag(flags, SUPPRESSED);
}

export function setSuppressedFlag(flags: number, value: boolean): number {
  return setFlag(flags, SUPPRESSED, value);
}

export function toggleSuppressedFlag(flags: number): number {
  return toggleFlag(flags, SUPPRESSED);
}

export function getArchivedFlag(flags: number): boolean {
  return getFlag(flags, ARCHIVED);
}

export function setArchivedFlag(flags: number, value: boolean): number {
  return setFlag(flags, ARCHIVED, value);
}

export function toggleArchivedFlag(flags: number): number {
  return toggleFlag(flags, ARCHIVED);
}

export function reallocateOverridesToAdded(
  overrides: ElementOverrideLookup,
  addedElements: ElementOverrideLookup
): [ElementOverrideLookup, ElementOverrideLookup] {
  const resultOverrides: ElementOverrideLookup = {};
  const resultAdded: ElementOverrideLookup = {};
  for (const [key, override] of Object.entries(overrides)) {
    if (addedElements[key]) {
      resultAdded[key] = { ...override, origin: 'ADD' } as Edit;
    } else {
      resultOverrides[key] = override;
    }
  }
  return [resultOverrides, resultAdded];
}

// TODO factor to some sort of passed config?
const translationTaskSlotBearingKinds: ScriptElementKind[] = [
  'SENTENCE',
  'CHAPTER',
  'PASSAGE',
];

const translationTaskSkipSlotDataKinds: ScriptElementKind[] = [
  'SPEAKER_LABEL',
  'UNRECOGNIZED',
];

export function translationTaskComputeElementKeys(
  element: ScriptElement,
  overrideExisting = false
) {
  // TODO assuming not called with decorator elements
  // need compute id, hash, claimed slot or slots
  if (!element.hash || overrideExisting) {
    element.hash = hashForElement(element);
  }
  if (!element.id) {
    if (element.origin === 'ADD') {
      element.id = randomString(12);
    } else {
      element.id = element.hash;
    }
  }
  if (translationTaskSkipSlotDataKinds.includes(element.kind)) {
    element.claimedSlot = null;
    element.slots = null;
    return;
  }
  if (
    element.kind === 'TRANSLATION' &&
    (!element.claimedSlot || overrideExisting)
  ) {
    element.claimedSlot = element.reference;
    element.slots = null;
  } else if (
    (!element.slots || overrideExisting) &&
    translationTaskSlotBearingKinds.includes(element.kind)
  ) {
    element.slots = [element.reference];
    element.claimedSlot = null;
  }
}

// TODO factor to some sort of passed config?
const structuralSlotBearingKinds: ScriptElementKind[] = [
  'CHAPTER_BREAK',
  'PASSAGE_BREAK',
];

// TODO factor to some sort of passed config?
const structuralTaskSkipSlotDataKinds: ScriptElementKind[] = [
  'SENTENCE',
  'SPEAKER_LABEL',
  'UNRECOGNIZED',
];

export function structuralTaskComputeElementKeys(
  element: ScriptElement,
  overrideExisting = false
) {
  // TODO assuming not called with decorator elements
  if (!element.hash || overrideExisting) {
    element.hash = hashForElement(element);
  }
  if (!element.id) {
    element.id = randomString(12);
  }
  if (structuralTaskSkipSlotDataKinds.includes(element.kind)) {
    element.claimedSlot = null;
    element.slots = null;
    return;
  }

  if (
    !structuralSlotBearingKinds.includes(element.kind) &&
    (!element.claimedSlot || overrideExisting)
  ) {
    element.claimedSlot = `${element.kind}:${element.reference}`;
    element.slots = null;
  } else if (!element.slots || overrideExisting) {
    let slots: string[] = [];
    if (element.kind === 'CHAPTER_BREAK') {
      slots.push(`CHAPTER:${element.reference}`);
      slots.push(`CHAPTER_SUMMARY:${element.reference}`);
    }
    if (element.kind === 'PASSAGE_BREAK') {
      slots.push(`PASSAGE:${element.reference}`);
      slots.push(`PASSAGE_SUMMARY:${element.reference}`);
    }
    if (!slots.length) {
      slots = null;
    }
    element.slots = slots;
    element.claimedSlot = null;
  }
}

const vocabTaskSlotBearingKinds: ScriptElementKind[] = ['VOCAB'];

const vocabTaskSkipSlotDataKinds: ScriptElementKind[] = [
  'SENTENCE',
  'SPEAKER_LABEL',
  'UNRECOGNIZED',
];

export function vocabTaskComputeElementKeys(
  element: ScriptElement,
  overrideExisting = false
) {
  // TODO TODO
  // TODO assuming not called with decorator elements
  // need compute id, hash, claimed slot or slots
  if (!element.hash || overrideExisting) {
    element.hash = hashForElement(element);
  }
  if (!element.id) {
    if (element.origin === 'ADD') {
      element.id = randomString(12);
    } else {
      element.id = element.hash;
    }
  }
  if (vocabTaskSkipSlotDataKinds.includes(element.kind)) {
    element.claimedSlot = null;
    element.slots = null;
    return;
  }
  if (
    (!element.slots || overrideExisting) &&
    vocabTaskSlotBearingKinds.includes(element.kind)
  ) {
    element.slots = [element.id];
    element.claimedSlot = null;
  }
}

const kindsToSynthesizeEleemntsFor: ScriptElementKind[] = [
  'CHAPTER',
  'CHAPTER_SUMMARY',
  'PASSAGE',
  'PASSAGE_SUMMARY',
];

export function structuralTaskSyntheticElementFor(
  element: ScriptElement,
  origin: ElementOrigin,
  timestamp: number
) {
  if (!kindsToSynthesizeEleemntsFor.includes(element.kind)) {
    return null;
  }
  const kind = (
    element.kind.startsWith('CHAPTER') ? 'CHAPTER_BREAK' : 'PASSAGE_BREAK'
  ) as any;

  const synthetic: ScriptElement = {
    id: null,
    kind,
    origin,
    reference: element.reference,
    text: '',
    hash: null,
    claimedSlot: null,
    slots: null,
    groupKey: null,
    timestamp,
  };
  structuralTaskComputeElementKeys(synthetic);
  return synthetic;
}
